import React, { useContext } from "react";
import { colors } from "../../../Utils/Colors";
import { row } from "../../../Utils/CommonStyles";
import CustomText from "../CustomText/CustomText";
import { useParams } from "react-router-dom";
import {} from "./style.css";

import imageBeauty from "../../../Assets/Collections/Banners/Web/PRODUCTOS-NATURALES-DE-BELLEZA-HORIZONTAL.jpg"
import imageDeportiva from "../../../Assets/Collections/Banners/Web/ROPA-DEPORTIVA-HORIZONTAL.jpg"
import imageFajas from "../../../Assets/Collections/Banners/Web/FAJAS-HORIZONTAL.jpg"
import imageJeans from "../../../Assets/Collections/Banners/Web/JEANS-HORIZONTAL.jpg"
import imageInterior from "../../../Assets/Collections/Banners/Web/ROPA-INTERIOR-DE-COMPRESIÓN-HORIZONTAL.jpg"

import image1 from "../../../Assets/Collections/Banners/Web/Brands/FUNAT.jpg";
import image2 from "../../../Assets/Collections/Banners/Web/Brands/FLEXMEE.jpg";
import image3 from "../../../Assets/Collections/Banners/Web/Brands/DIANE-AND-GEORDI.jpg";
import image4 from "../../../Assets/Collections/Banners/Web/Brands/LATY-ROSE.jpg";
import image5 from "../../../Assets/Collections/Banners/Web/Brands/DIANE-AND-GEORDI.jpg";
import image6 from "../../../Assets/Collections/Banners/Web/Brands/LOWLA.jpg";
import image7 from "../../../Assets/Collections/Banners/Web/Brands/SONRYSE.jpg";
import image8 from "../../../Assets/Collections/Banners/Web/Brands/DIANE-AND-GEORDI.jpg";
import image9 from "../../../Assets/Collections/Banners/Web/Brands/DIANE-AND-GEORDI.jpg";
import image10 from "../../../Assets/Collections/Banners/Web/Brands/BLING-SHAPERS.jpg";
import image11 from "../../../Assets/Collections/Banners/Web/Brands/MARIAE.jpg";
import image12 from "../../../Assets/Collections/Banners/Web/Brands/DIANE-AND-GEORDI.jpg";
import image13 from "../../../Assets/Collections/Banners/Web/Brands/DRAXY.jpg";
import image14 from "../../../Assets/Collections/Banners/Web/Brands/BE-SHAPY.jpg";
import image15 from "../../../Assets/Collections/Banners/Web/Brands/LOWLA.jpg";

import { StoreContext } from "../../../Context/storeContext";

export const Banner = ({ collection }) => {
	const store_data = useContext(StoreContext);
	let urlParams = useParams();
	
	// let collection_data = store_data.collections_all.filter((item) => item.handle === urlParams.collection)[0];
	let collection_images = {
		"beauty-health": imageBeauty,
		"ropa-deportiva": imageDeportiva,
		"fajas-colombianas": imageFajas,
		"jeans-colombianos": imageJeans,
		"ropa-interior": imageInterior,
		"shorts": imageInterior
	}
	let brand_images = {
		"funat labs": image1,
		flexmee: image2,
		"diane & geordi": image3,
		"laty rose": image4,
		"fajas myd": image5,
		LOWLA: image6,
		sonryse: image7,
		romanza: image8,
		"fajas salome": image9,
		"bling shapers": image10,
		"mariaE fajas": image11,
		upLady: image12,
		uplady: image12,
		draxy: image13,
		"be shapy": image14,
		lowla: image15,
	};
	// let banner_image = urlParams.collection ? collection_images[store_data.collections_all.filter((item) => item.handle === urlParams.collection)[0].handle] : urlParams.brand ? brand_images[urlParams.brand] : null ;
	let banner_image = urlParams.collection ? `https://admin.ventadirekta.com/images/collections/collection-${urlParams.collection}.jpg` : urlParams.brand ? `https://admin.ventadirekta.com/images/brands/collection/collection-${urlParams.brand.replace(' ', '-')}.jpg` : null ;
	return (
		<div style={{ height: "36vw" }}>
			<img
				src={banner_image}
				alt=""
				style={{ objectFit: "cover", width: "100%", height: "100%" }}
			/>
			<div
				style={{
					position: "absolute",
					marginLeft: 30,
					marginTop: -100,
				}}>
				<div style={row}>
					<CustomText
						fontSize={30}
						title={urlParams.collection ? store_data.collections_all.filter((item) => item.handle === urlParams.collection)[0].name : urlParams.brand ? urlParams.brand : ''}
						textAlign={"left"}
						fontFamily={"ClashDisplay-SemiBold"}
						color={colors.black}
					/>
				</div>
			</div>
		</div>
	);
};
