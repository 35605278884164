import React, { useState, useEffect, useContext } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { Grid } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Tooltip from "@mui/material/Tooltip";
import CircularProgress from "@mui/material/CircularProgress";
import { useTheme } from "@mui/material/styles";
import { colors } from "../../../Utils/Colors";
import Spacer from "../Spacer/Spacer";
import "../../../Assets/Style/style.css";
import "../../../Assets/Style/font.css";
import CustomText from "../CustomText/CustomText";

import { StoreContext } from "../../../Context/storeContext";
import productColors from "../../../Assets/Products/colors.json";
import {} from "./style.css";

export const FilterList = (props) => {
	let [searchParams, setSearchParams] = useSearchParams();
	const store_data = useContext(StoreContext);
	const theme = useTheme();
	let urlParams = useParams();

	// console.log(searchParams.get("color").replace(' ', ', '))
	// console.log(searchParams.get("size"))
	// console.log(searchParams.get("brand"))

	const [firstLoad, setFirstLoad] = useState(false);
	const [collectionUrl, setCollectionUrl] = useState(urlParams.collection);
	let filters_titles = store_data.filters;
	// let products = props.products;
	const [products, setproducts] = useState(props.products);
	// console.log(products)
	const [filtered_products, setfiltered_products] = useState([]);

	const [filterObject, setFilterObject] = useState({
		colors: searchParams.get("color") ? searchParams.get("color").split(" ") : [],
		sizes: searchParams.get("size") ? searchParams.get("size").split(" ") : [],
		brands: searchParams.get("brand") ? searchParams.get("brand").replaceAll(" ", ",").replaceAll("-", " ").replaceAll("and", "&").split(",") : [],
		status: null,
	});

	const [products_variants, setProducts_variants] = useState(
		products
			.map((item) => item.variants)
			.flat(1)
			.filter((item) => item.inventory_quantity > 0)
	);

	const [product_sizes, setproduct_sizes] = useState([...new Set(products_variants.map((item) => item.option1))]);
	const [product_colors, setproduct_colors] = useState([...new Set(products_variants.map((item) => item.option2))]);
	const [product_brands, setproduct_brands] = useState([...new Set(products.map((item) => item.brand))]);
	// let product_sizes = [...new Set(products_variants.map((item) => item.option1))];
	// let product_colors = [...new Set(products_variants.map((item) => item.option2))];
	// let product_brands = [...new Set(products.map((item) => item.brand))];
	let product_prices = [...new Set(products.map((item) => item.max_price.number))];

	const [expanded, setExpanded] = useState(false);
	const [load, setLoad] = useState(false);

	useEffect(() => {
		// console.log(filterObject);
		props.setLoad(false);

		let data_filtereda = [];

		for (const product of filterObject.brands.length > 0 ? props.products.filter((item) => filterObject.brands.some((v) => item.brand.includes(v.replaceAll('-', ' ')))) : props.products) {
			let product_filter = product;

			if (filterObject.colors.length > 0) {
				
				let variant = product_filter.variants.filter((item) => filterObject.colors.some((v) => item.option2.includes(v)) && item.inventory_quantity > 0);
				if (variant.length > 0) {
					// console.log(product)
					product_filter = product;
				} else {
					product_filter = null;
				}
			}

			if (filterObject.sizes.length > 0) {

				let variant = product_filter ? product_filter.variants.filter((item) => filterObject.sizes.some((v) => item.option1.includes(v)) && item.inventory_quantity > 0) : null;
				if (variant?.length > 0) {
					product_filter = product;
				} else {
					product_filter = null;
				}
			}

			if (product_filter) {
				data_filtereda.push(product);
			}
			// console.log(product_filter);
		}

		// console.log(data_filtereda);

		if (filterObject.colors.length > 0 || filterObject.sizes.length > 0 || filterObject.brands.length > 0) {
			let filter_colors = filterObject.colors.join("+");
			let filter_sizes = filterObject.sizes.join("+");
			let filter_brands = filterObject.brands.map((item) => item.replaceAll(" ", "-").replaceAll("&", "and").trim()).join("+");

			let urlQueryColors = `color=${filter_colors}`;
			let urlQuerySizes = `size=${filter_sizes}`;
			let urlQueryBrands = `brand=${filter_brands}`;
			let urlQuery = `${urlQueryColors}&${urlQuerySizes}&${urlQueryBrands}`;
			setSearchParams(urlQuery);
			setTimeout(() => {
				props.setFilterProducts(data_filtereda);
				props.setSetFilters(true);
				props.setLoad(true);
			}, 2000);
		} else {
			props.setFilterProducts([]);
			props.setSetFilters(false);
			setTimeout(() => {
				props.setLoad(true);
			}, 1000);
			setSearchParams();
		}
	}, [filterObject]);

	useEffect(() => {
		// if (!firstLoad) {
			
		// 	setFilterObject({ colors: [], sizes: [], brands: [], status: null });
		// }else{
			// 	setFirstLoad(true);
			// }
		if (collectionUrl !== urlParams.collection) {
				
			setFilterObject({ colors: [], sizes: [], brands: [], status: null });
			setCollectionUrl(urlParams.collection)
		}
		setLoad(false);
		setfiltered_products([]);
		props.setFilterProducts([]);
		props.setSetFilters(false);

		setTimeout(() => {
			setproducts(props.products);
			let productsFilters = props.products
				.map((item) => item.variants)
				.flat(1)
				.filter((item) => item.inventory_quantity > 0);
			setProducts_variants(productsFilters);

			setproduct_sizes([...new Set(productsFilters.map((item) => item.option1))]);
			setproduct_colors([...new Set(productsFilters.map((item) => item.option2))]);
			setproduct_brands([...new Set(props.products.map((item) => item.brand))]);
		}, 2000);
		setTimeout(() => {
			setLoad(true);
		}, 2000);
	}, [urlParams.collection, urlParams.data]);

	const handleChange = (panel) => (event, isExpanded) => {
		setExpanded(isExpanded ? panel : false);
	};

	return (
		<div className="filters" style={{ zIndex: 0, backgroundColor: theme.palette.background.default }}>
			<h5
				style={{
					fontFamily: "ClashDisplay-Regular",
					color: theme.palette.primary.main,
					fontSize: "15px",
					fontWeight: "100",
				}}>
				{store_data.translations["translate-collection-body-1"].toUpperCase()}
			</h5>
			<Spacer height={30} />

			{load ? (
				<div>
					<Accordion expanded={expanded === "panel1"} onChange={handleChange("panel1")} style={{ boxShadow: "none", border: "none", backgroundColor: theme.palette.background.default }}>
						<AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1bh-content" id="panel1bh-header">
							<h5
								style={{
									fontFamily: "ClashDisplay-Regular",
									color: theme.palette.primary.main,
									fontSize: "15px",
									fontWeight: "100",
								}}>
								{filters_titles.filter((item) => item.handle === "color")[0].name.toUpperCase()}
								{/* COLOR */}
							</h5>
						</AccordionSummary>
						<AccordionDetails>
							<Grid container rowSpacing={{ xs: 1 }} columnSpacing={{ xs: 1 }} className="colors">
								{product_colors.map((item, index) =>
									// color.includes(item) ? (
									filterObject.colors.includes(item) ? (
										<Grid item xs={2} key={index}>
											<div
												className="color-select selected"
												style={{
													borderColor: colors.black,
												}}>
												<Tooltip title={item} variant="soft">
													<div
														className="color"
														// onClick={() => colorFiltering(item, "remove")}
														onClick={() => {
															setFilterObject({ colors: filterObject.colors.filter((e) => e !== item), sizes: filterObject.sizes, brands: filterObject.brands, status: "remove" });
														}}
														style={{
															borderColor: colors.black,
															backgroundColor: productColors[item],
														}}></div>
												</Tooltip>
											</div>
										</Grid>
									) : (
										<Grid item xs={2} key={index}>
											<div
												className="color-select"
												style={{
													borderColor: colors.black,
												}}>
												<Tooltip title={item} variant="soft">
													<div
														className="color"
														// onClick={() => colorFiltering(item, "add")}
														onClick={() => {
															setFilterObject({ colors: filterObject.colors.concat(item), sizes: filterObject.sizes, brands: filterObject.brands, status: "add" });
														}}
														style={{
															borderColor: colors.black,
															backgroundColor: productColors[item],
														}}></div>
												</Tooltip>
											</div>
										</Grid>
									)
								)}
							</Grid>
						</AccordionDetails>
					</Accordion>
					<Accordion expanded={expanded === "panel2"} onChange={handleChange("panel2")} style={{ boxShadow: "none", border: "none", backgroundColor: theme.palette.background.default }}>
						<AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2bh-content" id="panel2bh-header">
							<h5
								style={{
									fontFamily: "ClashDisplay-Regular",
									color: theme.palette.primary.main,
									fontSize: "15px",
									fontWeight: "100",
								}}>
								{filters_titles.filter((item) => item.handle === "size")[0].name.toUpperCase()}
								{/* SIZES */}
							</h5>
						</AccordionSummary>
						<AccordionDetails>
							<Grid container rowSpacing={{ xs: 1 }} columnSpacing={{ xs: 1 }} className="colors">
								{product_sizes.map((item, index) =>
									// size.includes(item) ? (
									filterObject.sizes.includes(item) ? (
										<Grid item xs={4} key={item}>
											<CustomText title={item} fontSize={14} color={colors.colorB} cursor={"pointer"} fontWeight={800} onClick={() => setFilterObject({ colors: filterObject.colors, sizes: filterObject.sizes.filter((e) => e !== item), brands: filterObject.brands, status: "remove" })} />
											{/* <CustomText title={item} fontSize={14} color={colors.colorB} cursor={"pointer"} fontWeight={800} onClick={() => sizeFiltering(item, "remove")} /> */}
										</Grid>
									) : (
										<Grid item xs={4} key={item}>
											{/* <CustomText title={item} fontSize={14} color={colors.colorB} cursor={"pointer"} onClick={() => sizeFiltering(item, "add")} /> */}
											<CustomText
												title={item}
												fontSize={14}
												color={colors.colorB}
												cursor={"pointer"}
												onClick={() => {
													setFilterObject({ colors: filterObject.colors, sizes: filterObject.sizes.concat(item), brands: filterObject.brands, status: "add" });
												}}
											/>
										</Grid>
									)
								)}
							</Grid>
						</AccordionDetails>
					</Accordion>
					<Accordion expanded={expanded === "panel3"} onChange={handleChange("panel3")} style={{ boxShadow: "none", border: "none", backgroundColor: theme.palette.background.default }}>
						<AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1bh-content" id="panel1bh-header">
							<h5
								style={{
									fontFamily: "ClashDisplay-Regular",
									color: theme.palette.primary.main,
									fontSize: "15px",
									fontWeight: "100",
								}}>
								{filters_titles.filter((item) => item.handle === "brands")[0].name.toUpperCase()}
								{/* BRANDS */}
							</h5>
						</AccordionSummary>
						<AccordionDetails>
							<Grid container rowSpacing={{ xs: 1 }} columnSpacing={{ xs: 1 }}>
								{product_brands.map((item, index) =>
									// brand.includes(item) ? (
									filterObject.brands.includes(item) ? (
										<Grid item xs={12} key={index} style={{ alignSelf: "center" }}>
											<CustomText title={item} textAlign={"left"} fontSize={14} fontWeight={800} color={colors.colorB} cursor={"pointer"} onClick={() => setFilterObject({ colors: filterObject.colors, sizes: filterObject.sizes, brands: filterObject.brands.filter((e) => e.replaceAll(' ', '-') !== item.replaceAll(' ', '-')), status: "remove" })} />
											{/* <CustomText title={item} textAlign={"left"} fontSize={14} fontWeight={800} color={colors.colorB} cursor={"pointer"} onClick={() => brandFiltering(item, "remove")} /> */}
										</Grid>
									) : (
										<Grid item xs={12} key={index} style={{ alignSelf: "center" }}>
											<CustomText
												title={item}
												textAlign={"left"}
												fontSize={14}
												color={colors.colorB}
												cursor={"pointer"}
												onClick={() => {
													setFilterObject({ colors: filterObject.colors, sizes: filterObject.sizes, brands: filterObject.brands.concat(item), status: "add" });
												}}
											/>
											{/* <CustomText title={item} textAlign={"left"} fontSize={14} color={colors.colorB} cursor={"pointer"} onClick={() => brandFiltering(item, "add")} /> */}
										</Grid>
									)
								)}
							</Grid>
						</AccordionDetails>
					</Accordion>
					{/* <Accordion
						expanded={expanded === "panel4"}
						onChange={handleChange("panel4")}
						style={{boxShadow: "none", border: "none", backgroundColor: theme.palette.background.default}}>
						<AccordionSummary
							expandIcon={<ExpandMoreIcon />}
							aria-controls="panel2bh-content"
							id="panel2bh-header">
							<h5
								style={{
									fontFamily: "ClashDisplay-Regular",
									color: theme.palette.primary.main,
									fontSize: "15px",
									fontWeight: "100",
								}}>
								PRICE
							</h5>
						</AccordionSummary>
						<AccordionDetails>
							<Grid
								container
								rowSpacing={{ xs: 1 }}
								columnSpacing={{ xs: 1 }}
								className="colors">
								<Grid item xs={6}>
									<TextField
										id="outlined-basic"
										label="Min Price"
										variant="outlined"
									/>
								</Grid>
								<Grid item xs={6}>
									<TextField
										id="outlined-basic"
										label="Max Price"
										variant="outlined"
									/>
								</Grid>
							</Grid>
						</AccordionDetails>
					</Accordion> */}
					{filtered_products.length > 0 ? (
						<CustomText
							title={"Limpiar Filtros"}
							fontSize={14}
							color={theme.palette.primary.main}
							cursor={"pointer"}
							fontWeight={800}
							onClick={() => {
								setLoad(false);
								setfiltered_products([]);
								props.setFilterProducts([]);
								setFilterObject({ colors: [], sizes: [], brands: [], status: null });
								// setSearchParams();
								setProducts_variants(
									filtered_products.length > 0
										? filtered_products
												.map((item) => item.variants)
												.flat(1)
												.filter((item) => item.inventory_quantity > 0)
										: props.products
												.map((item) => item.variants)
												.flat(1)
												.filter((item) => item.inventory_quantity > 0)
								);
								setTimeout(() => {
									setLoad(true);
								}, 1000);
							}}
						/>
					) : (
						""
					)}
				</div>
			) : (
				<div
					style={{
						display: "inline-flex",
						justifyContent: "center",
						alignItems: "center",
						width: "100%",
						height: "160px",
					}}>
					<CircularProgress style={{ color: theme.palette.primary.main }} />
				</div>
			)}
		</div>
	);
};

const FilterItem = ({ label, icon, iconPath, paddingLeft }) => (
	<div
		style={{
			display: "flex",
			justifyContent: "space-between",
			alignItems: "center",
			width: "60%",
		}}>
		<div
			style={{
				color: colors.colorB,
				textDecoration: "none",
				paddingLeft: paddingLeft || 50,
				cursor: "pointer",
				fontFamily: "ClashDisplay-Regular",
			}}>
			{label}
		</div>
		{icon ? <img src={iconPath} alt="" style={{ height: 10 }} /> : <></>}
	</div>
);
