import React from "react";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import { NavbarMobile } from "./NavbarMobile";
import { NavWeb } from "./NavWeb";
import {} from "./style.css";

const Item = styled(Paper)(({ theme }) => ({
	backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
	...theme.typography.body2,
	padding: theme.spacing(1),
	textAlign: "center",
	color: theme.palette.text.secondary,
}));

function Navbar(props) {
	return (
		<nav>
			{/* {window.innerWidth <= 951 ? (
				""
			) : (
				<DiscountBanner/>
			)} */}
			{window.innerWidth <= 1024 ? <NavbarMobile light={props.light} setLight={props.setLight} drawer={props.drawer} isDrawerOpen={props.isDrawerOpen} setIsDrawerOpen={props.setIsDrawerOpen} /> : <NavWeb light={props.light} setLight={props.setLight} drawer={props.drawer} />}
		</nav>
	);
}

export default Navbar;
