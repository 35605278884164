import React, { useContext, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Grid } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import CircularProgress from '@mui/material/CircularProgress';

import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Lazy } from "swiper";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import "./styles.css";

// import required modules
import Spacer from "../Spacer/Spacer";
import CustomText from "../CustomText/CustomText";
import { colors } from "../../../Utils/Colors";
import { Link } from "react-router-dom";
import FilledRightCircle from "../FilledCircle/FilledCircle";

import { AddToCart } from "../productActions/productActions";
import { ProductColors, ProductSizes } from "../index";

import { StoreContext } from "../../../Context/storeContext";

const NewArrivals = (props) => {
	const theme = useTheme();
	const store_data = useContext(StoreContext);
	let urlParams = useParams();
	const [currentSlide, setCurrentSlide] = useState(0);
	const [SlideEnd, setSlideEnd] = useState(false);
	const [load, setLoad] = useState(false);

	const [collectionData, setCollectionData] = useState(urlParams.collection ? store_data.collections_all.filter((item) => item.handle === urlParams.collection) : null);
	const [brandData, setBrandData] = useState(urlParams.brand ? store_data.filter_products.filter((item) => item.brand.toLowerCase() === urlParams.brand.toLowerCase() && item.tags.includes("New")) : null);
	const [products, setProducts] = useState(collectionData ? store_data.filter_products.filter((item) => item.tags.includes("New") && item.collections.includes(collectionData[0].shopify_id)) : brandData ? brandData : store_data.filter_products.filter((item) => item.tags.includes("New")));

	useEffect(() => {
		setLoad(false);
		setCollectionData(urlParams.collection ? store_data.collections_all.filter((item) => item.handle === urlParams.collection) : null);
		setTimeout(() => {
			setLoad(true);
		}, 1000);
	}, [urlParams]);
	if (products.length > 0) {
		return (
			<div
				className="new-arrivals"
				style={{
					backgroundColor: theme.palette.background.default,
					paddingBottom: products.length <= 4 ? "24px" : "",
				}}>
				{/* <Spacer height={100} /> */}
				<div style={{ display: "flex", flexDirection: "row" }}>
					<Link to="/new-arrivals" style={{ textDecoration: "none", padding: 0 }}>
						<div style={{ display: "flex" }}>
							<CustomText title={store_data.translations["translate-all-pages-body-17"]} fontFamily={"ClashDisplay-SemiBold"} fontSize={window.innerWidth <= 700 ? 25 : 45} color={theme.palette.primary.main} />
							<CustomText title={store_data.translations["translate-all-pages-body-18"]} fontSize={window.innerWidth <= 700 ? 25 : 45} color={theme.palette.text.primary} />
						</div>
					</Link>
					<Spacer width={45} />
					<div
						style={{
							height: 1,
							width: "100%",
							backgroundColor: theme.palette.text.primary,
							alignSelf: "center",
						}}></div>
				</div>
				<Spacer height={window.innerWidth <= 645 ? 0 : 50} />
				<div
					style={{
						width: window.innerWidth <= 1000 ? "100%" : "auto",
						// paddingRight: window.innerWidth <= 1000 ? "" : 100,
						// paddingLeft: window.innerWidth <= 1000 ? "" : 100,
					}}>
					<Spacer height={window.innerWidth <= 1000 ? 40 : ""} />
					<Swiper
						spaceBetween={10}
						slidesPerView={window.innerWidth >= 900 ? 4 : window.innerWidth <= 650 ? 1 : 2}
						allowTouchMove={window.innerWidth <= 650 ? true : false}
						modules={[Pagination]}
						pagination={window.innerWidth <= 650 ? true : false}
						onSlideChange={(swiper) => {
							setCurrentSlide(swiper.activeIndex);
							setSlideEnd(swiper.isEnd);
						}}>
						{currentSlide > 0 && window.innerWidth > 650 ? <FilledRightCircle top="-30vh" left={true} background={theme.palette.background.default} /> : ""}
						{products.map((item, index) => (
							<SwiperSlide
								key={index}
								style={{
									display: "inline-block",
									backgroundColor: theme.palette.background.default,
								}}>
								<FormatProduct product={item} light={props.light} />
							</SwiperSlide>
						))}
						{!SlideEnd && window.innerWidth > 650 && products.length > 4 ? <FilledRightCircle top="-30vh" background={theme.palette.background.default} /> : ""}
					</Swiper>
				</div>
			</div>
		);
	}
};

const FormatProduct = ({ product, light }) => {
	const store_data = useContext(StoreContext);
	const theme = useTheme();
	let variants = product.variants.filter((item) => item.inventory_quantity > 0);
	let first_variant = variants[0];
	const [mainImage, setMainImage] = useState(product.image);
	const [productVariant, setProductVariant] = useState(first_variant.sku);
	const [maxQuantity, setMaxQuantity] = useState(first_variant.inventory_quantity);
	const [imageLoaded, setImageLoaded] = useState(false);

	let product_title_array = product.title.split(" ");
	let product_title_array_length = product_title_array.length;
	let product_title_one = product_title_array
		.slice(0, Math.floor(product_title_array_length / 2))
		.join(" ")
		.toString();

	return (
		<div className="product" style={{ paddingBottom: window.innerWidth < 650 ? "30px" : 0 }}>
			<Grid container rowSpacing={1}>
				<Link to={`/products/${product.handle}`}>
					<Swiper id="card-product-collection" spaceBetween={0} slidesPerView={1} loop="false" allowTouchMove={true} modules={[Pagination, Lazy]} pagination={true} lazy={true}>
						{product.images.map((item, index) => (
							<SwiperSlide
								key={index}
								style={{
									display: "inline-flex",
									justifyContent: "center",
									alignItems: "center",
									backgroundColor: "#fff",
									contentVisibility: "auto",
									height: "40vh"
								}}>
									{imageLoaded ? '' : (
										<CircularProgress style={{position: "absolute"}}/>
									)}
									<img src={`${item.src}&width=400`} alt="" style={{ height: "40vh", width: "auto" }} loading="lazy" onLoad={() => setImageLoaded(true)}/>
									<p className="new-text" style={{ color: theme.palette.text.secondary, backgroundColor: theme.palette.secondary.main }}>
										{store_data.translations["translate-all-pages-body-16"]}
									</p>
							</SwiperSlide>
						))}
					</Swiper>
					{/* <div className="image-big" style={{ backgroundImage: `url(${mainImage})` }}>
						<div className="new-text" style={{ backgroundColor: theme.palette.secondary.main }}>
							<p style={{ color: theme.palette.text.secondary }}>NEW</p>
						</div>
					</div> */}
				</Link>

				<Grid item xs={12}>
					<Grid container rowSpacing={1}>
						<Grid item xs={6}>
							<div className="title">
								<Link to={`/products/${product.handle}`} style={{ textDecoration: "none" }}>
									<CustomText title={product_title_one} titleTL={product_title_one} fontFamily="ClashDisplay-SemiBold" fontSize={16} wrap={true} />
								</Link>
								<Link
									to={`/brands/${product.brand.toLowerCase()}`}
									style={{
										textDecoration: "none",
									}}>
									<CustomText
										fontSize={16}
										title={product.brand}
										textAlign={"left"}
										color={"#000"}
										// color={Ucolors.white}
									/>
								</Link>
								{/* <CustomText title={product_title_two} fontSize={30} /> */}
							</div>
						</Grid>
						<Grid item xs={6}>
							<div className="variants-div">
								<div className="sizes">
									<ProductSizes light={light} variants={product.variants} productVariant={productVariant} setProductVariant={setProductVariant} setMaxQuantity={setMaxQuantity} />
								</div>
								{/* <ProductColors variants={product.variants} productVariant={productVariant} setProductVariant={setProductVariant} setMaxQuantity={setMaxQuantity} /> */}
								<ProductColors variants={product.variants} productVariant={productVariant} setProductVariant={setProductVariant} setMaxQuantity={setMaxQuantity} />
							</div>
						</Grid>
					</Grid>
				</Grid>

				<Grid item xs={12}>
					<Grid container rowSpacing={1} className="info">
						<Grid item xs={6}>
							<CustomText className="price" textAlign={"left"} title={product.max_price.format} fontSize={30} color={colors.colorB} />
						</Grid>
						<Grid item xs={6}>
							<div className="productActions">
								<AddToCart
									action="add"
									quantity={1}
									id={product._id}
									shopify_id={product.shopify_id}
									title={product.title}
									brand={product.brand}
									description={product.description.replace("<p>", "").replace("</p>", "").split("<ul>")[0]}
									price={product.max_price.number}
									total={product.max_price.number * 1}
									handle={product.handle}
									variant={{
										sku: productVariant,
										color: product.variants.find((item) => item.sku == productVariant).option2,
										size: product.variants.find((item) => item.sku == productVariant).option1,
										variant_id: product.variants.find((item) => item.sku == productVariant).id,
									}}
									image={product.image == "/images/default/product_default.png" ? "https://sexymeshape.ventadirekta.com/images/default/product_default.png" : product.image}
								/>
							</div>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</div>
	);
};
export default NewArrivals;
