import React, { useContext, useEffect, useState } from "react";
import { center, row } from "../../../Utils/CommonStyles";
import { Grid } from "@mui/material";
import Badge from "@mui/material/Badge";
import { useTheme } from "@mui/material/styles";
import { Link } from "react-router-dom";
import { colors } from "../../../Utils/Colors";
import { MuiDrawer } from "../Sidebar/Sidebar";
import CustomText from "../CustomText/CustomText";
import Spacer from "../Spacer/Spacer";
import SearchComponent from "../SearchComponent/SearchComponent";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import DensityMediumIcon from "@mui/icons-material/DensityMedium";
import Button from "@mui/material/Button";
import {} from "./style.css";

import Menu from "@mui/material/Menu";
import MenuList from "@mui/material/MenuList";
import MenuItem from "@mui/material/MenuItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";

import { StoreContext, LanguageContext } from "../../../Context/storeContext";

export const NavBar = (props) => {
	const theme = useTheme();
	const store_data = useContext(StoreContext);
	const { language, setLanguage } = useContext(LanguageContext);
	const [scroll, setScroll] = useState(0);

	const [anchorEl, setAnchorEl] = React.useState(null);
	const open = Boolean(anchorEl);
	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	const [cartLength, setcartLength] = useState(
		JSON.parse(localStorage.getItem("cart")).length > 0
			? JSON.parse(localStorage.getItem("cart"))
					.map((item) => item.quantity)
					.reduce((a, b) => a + b)
			: 0
	);

	window.addEventListener("storage", (e) => {
		setcartLength(
			JSON.parse(localStorage.getItem("cart")).length > 0
				? JSON.parse(localStorage.getItem("cart"))
						.map((item) => item.quantity)
						.reduce((a, b) => a + b)
				: 0
		);
	});

	useEffect(() => {
		setScroll(window.scrollY);
		const handleScroll = (event) => {
			setScroll(window.scrollY);
		};

		window.addEventListener("scroll", handleScroll);

		return () => {
			window.removeEventListener("scroll", handleScroll);
		};
	}, []);

	return (
		<>
			<Grid className="navBarGeneral" container rowSpacing={{ xs: 5, sm: 2, md: 3 }} columnSpacing={{}} style={{ top: scroll > 0 ? "0" : "" }}>
				<Grid
					container
					rowSpacing={{ xs: 5, sm: 2, md: 3 }}
					style={{
						backgroundColor: theme.palette.background.default,
						paddingTop: 0,
						marginTop: 0,
						height: window.innerWidth <= 640 ? "8vh" : "",
					}}>
					<Grid
						item
						xs={3}
						sm={4}
						style={{
							paddingLeft: window.innerWidth <= 640 ? 10 : 40,
							paddingTop: 0,
							backgroundColor: theme.palette.background.default,
							display: "inline-flex",
							alignItems: "center",
						}}>
						<Link to="/" style={{ textDecoration: "none" }}>
							<CustomText title={store_data.store_name.length > 4 ? store_data.initials : store_data.store_name} fontSize={18} textAlign={"left"} fontFamily="ClashDisplay-SemiBold" textTransform="capitalize" />
						</Link>
					</Grid>
					<Grid
						item
						xs={8}
						style={{
							paddingRight: window.innerWidth <= 640 ? 10 : 20,
							display: "inline-flex",
							alignItems: "center",
							justifyContent: "space-between",
							paddingTop: "10px",
						}}>
						<div style={{ ...row, padding: 5, ...center }}>
							<SearchComponent />
						</div>
						<div
							style={{
								...center,
							}}>
							{window.innerWidth >= 640 ? (

								<Button id="basic-button" aria-controls={open ? "basic-menu" : undefined} aria-haspopup="true" aria-expanded={open ? "true" : undefined} onClick={handleClick}>
									<img src={`https://admin.ventadirekta.com${store_data.current_language.flag}`} alt="" width={30} height={30} />
								</Button>
							) : ''}
							<Link to="/cart" style={{ color: colors.colorB }}>
								{/* <CssVarsProvider> */}
								<Badge badgeContent={cartLength} color="primary" variant="solid">
									<ShoppingCartOutlinedIcon />
								</Badge>
								{/* </CssVarsProvider> */}
							</Link>
							<Spacer width={50} />
							<a
								href="#"
								onClick={(e) => {
									e.preventDefault();
									props.setIsDrawerOpen(true);
								}}>
								<DensityMediumIcon style={{ color: "#000" }} />
							</a>
							<MuiDrawer isDrawerOpen={props.isDrawerOpen} setIsDrawerOpen={props.setIsDrawerOpen} />
							<Spacer width={50} />
							{/* <ToggleSwitch light={props.light} setLight={props.setLight} /> */}
						</div>
					</Grid>
				</Grid>
			</Grid>
			<Menu
				id="basic-menu"
				anchorEl={anchorEl}
				open={open}
				onClose={handleClose}
				MenuListProps={{
					"aria-labelledby": "basic-button",
				}}>
				<MenuList>
					{JSON.parse(store_data.languages).map((item, index) => {
						return (
							<MenuItem key={index}>
								<ListItemIcon>
									<img src={`https://admin.ventadirekta.com${item.flag}`} alt="" width={30} height={30} />
								</ListItemIcon>
								<ListItemText
									onClick={(e) => {
										setLanguage(item.code);
									}}>
									{item.name}
								</ListItemText>
							</MenuItem>
						);
					})}
				</MenuList>
			</Menu>
		</>
	);
};
