import * as React from "react";
import { Link, useParams } from "react-router-dom";
import CustomText from "../CustomText/CustomText";

import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { styled } from "@mui/material/styles";
import { useTheme } from "@mui/material/styles";
import Divider from "@mui/material/Divider";

import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import EmailIcon from "@mui/icons-material/Email";
import AddBusinessIcon from "@mui/icons-material/AddBusiness";
import HomeIcon from "@mui/icons-material/Home";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import WidgetsIcon from "@mui/icons-material/Widgets";
import HeadsetMicIcon from "@mui/icons-material/HeadsetMic";
import MenuIcon from "@mui/icons-material/Menu";

import { StoreContext } from "../../../Context/storeContext";

const StyledMenu = styled((props) => (
	<Menu
		elevation={0}
		anchorOrigin={{
			vertical: "top",
			horizontal: "left",
		}}
		transformOrigin={{
			vertical: "bottom",
			horizontal: "left",
		}}
		{...props}
	/>
))(({ theme }) => ({
	"& .MuiPaper-root": {
		borderRadius: 0,
		top: "auto !important",
		bottom: "55px",
		width: "100vw",
		maxWidth: "100vw",
		left: "0 !important",
		// backgroundColor: theme.palette.primary.main,
		backgroundColor: theme.palette.background.default,
		// color: theme.palette.text.secondary,
		color: theme.palette.primary.main,
		fontFamily: "ClashDisplay-Regular",
		boxShadow: "rgba(0, 0, 0, 0.2) 0px -5px 5px -3px, rgba(0, 0, 0, 0.14) 0px -8px 10px 1px, rgba(0, 0, 0, 0.12) 0px -16px 14px 2px",
	},
}));

export default function BottomBarNavigation({ footer = true, cart = false, ...props }) {
	const store_data = React.useContext(StoreContext);
	let urlParams = useParams();
	const theme = useTheme();

	let collections_data = JSON.parse(store_data.collections);
	const [value, setValue] = React.useState("recents");

	const [anchorElCategories, setAnchorElCategories] = React.useState(null);
	const openCategories = Boolean(anchorElCategories);
	const handleClickCategories = (event) => {
		setAnchorElCategories(event.currentTarget);
	};
	const handleCloseCategories = () => {
		setAnchorElCategories(null);
	};

	const [anchorElCustomer, setAnchorElCustomer] = React.useState(null);
	const openCustomer = Boolean(anchorElCustomer);
	const handleClickCustomer = (event) => {
		setAnchorElCustomer(event.currentTarget);
	};
	const handleCloseCustomer = () => {
		setAnchorElCustomer(null);
	};

	const handleChange = (event, newValue) => {
		setValue(newValue);
	};

	const [filterModal, setFilterModal] = React.useState(document.getElementById("buttonFilterModal"));

	const handleOpenModalFilters = () => {
		filterModal.click();
	};

	React.useEffect(() => {
		setFilterModal(document.getElementById("buttonFilterModal"));
	}, [urlParams]);

	return (
		<>
			<div
				style={{
					position: "absolute",
					display: "flex",
					justifyContent: "space-between",
					zIndex: 100,
				}}>
				<BottomNavigation sx={{ width: "100%", position: "fixed", bottom: 0 }} value={value} onChange={handleChange}>
					<Link
						to="/"
						style={{
							textDecoration: "none",
							display: "flex",
							justifyContent: "center",
						}}>
						<BottomNavigationAction value="recents" icon={<HomeIcon style={{ height: 25, width: 25, color: theme.palette.secondary.main }} />} />
					</Link>
					{Object.keys(urlParams).toString() === "collection" || Object.keys(urlParams).toString() === "data" ? (
						<BottomNavigationAction onClick={handleOpenModalFilters} style={{ border: 0, backgroundColor: "transparent", width: "100%" }} value="nearby" icon={<FilterAltIcon style={{ height: 25, width: 25, color: theme.palette.secondary.main }} />} />
					) : (
						<BottomNavigationAction onClick={handleClickCategories} style={{ border: 0, backgroundColor: "transparent", width: "100%" }} value="favorites" icon={<WidgetsIcon style={{ height: 25, width: 25, color: theme.palette.secondary.main }} />} />
					)}
					<BottomNavigationAction onClick={handleClickCustomer} style={{ border: 0, backgroundColor: "transparent", width: "100%" }} value="nearby" icon={<HeadsetMicIcon style={{ height: 25, width: 25, color: theme.palette.secondary.main }} />} />
					<BottomNavigationAction onClick={() => props.setIsDrawerOpen(true)} style={{ border: 0, backgroundColor: "transparent", width: "100%" }} value="folder" icon={<MenuIcon style={{ height: 25, width: 25, color: theme.palette.secondary.main }} />} />
				</BottomNavigation>
			</div>

			<StyledMenu anchorEl={anchorElCategories} open={openCategories} onClose={handleCloseCategories}>
				<CustomText title={store_data.translations["translate-all-pages-footer-9"]} textTransform="uppercase" color={theme.palette.primary.main} fontFamily="ClashDisplay-SemiBold" textAlign="center" fontSize={20} />
				{collections_data.map((item, index) => (
					<Link key={"/" + item.handle} to={"/collection/" + item.handle} style={{ textDecoration: "none", color: theme.palette.primary.main }}>
						<MenuItem onClick={handleCloseCategories}>
							<p style={{ fontFamily: "ClashDisplay-Regular" }}>{item.name}</p>
						</MenuItem>
					</Link>
				))}
				<Link to={"/new-arrivals"} style={{ textDecoration: "none", color: theme.palette.primary.main }}>
					<MenuItem onClick={handleCloseCategories}>
						<p style={{ fontFamily: "ClashDisplay-SemiBold" }}>{store_data.translations["translate-home-body-19"]} </p>
					</MenuItem>
				</Link>
			</StyledMenu>
			<StyledMenu anchorEl={anchorElCustomer} open={openCustomer} onClose={handleCloseCustomer}>
				<CustomText title={store_data.translations["translate-all-pages-footer-10"]} textTransform="uppercase" color={theme.palette.primary.main} fontFamily="ClashDisplay-SemiBold" textAlign="center" fontSize={20} />
				{store_data.whatsapp ? (
					<MenuItem onClick={handleCloseCustomer}>
						<Link to={`https://wa.me/${store_data.store_phone_link}`} style={{ display: "inline-flex", width: "100%", textDecoration: "none" }}>
							<WhatsAppIcon style={{ color: theme.palette.primary.main, marginRight: 2 }} />
							<CustomText title={store_data.store_phone_link} color={theme.palette.primary.main} fontSize={13} />
						</Link>
					</MenuItem>
				) : (
					""
				)}
				{store_data.facebook ? (
					<MenuItem onClick={handleCloseCustomer}>
						<Link to={store_data.facebook} style={{ display: "inline-flex", width: "100%", textDecoration: "none" }}>
							<FacebookIcon style={{ color: theme.palette.primary.main, marginRight: 2 }} />
							<CustomText title={store_data.facebook.split(".com/")[1]} color={theme.palette.primary.main} fontSize={13} />
						</Link>
					</MenuItem>
				) : (
					""
				)}
				{store_data.twitter ? (
					<MenuItem onClick={handleCloseCustomer}>
						<Link to={store_data.twitter} style={{ display: "inline-flex", width: "100%", textDecoration: "none" }}>
							<TwitterIcon style={{ color: theme.palette.primary.main, marginRight: 2 }} />
							<CustomText title={store_data.twitter.split(".com/")[1]} color={theme.palette.primary.main} fontSize={13} />
						</Link>
					</MenuItem>
				) : (
					""
				)}
				{store_data.instagram ? (
					<MenuItem onClick={handleCloseCustomer}>
						<Link to={store_data.instagram} style={{ display: "inline-flex", width: "100%", textDecoration: "none" }}>
							<InstagramIcon style={{ color: theme.palette.primary.main, marginRight: 2 }} />
							<CustomText title={store_data.instagram.split(".com/")[1]} color={theme.palette.primary.main} fontSize={13} />
						</Link>
					</MenuItem>
				) : (
					""
				)}
				{store_data.store_email ? (
					<MenuItem onClick={handleCloseCustomer}>
						<Link to={`mailto:${store_data.store_email}`} style={{ display: "inline-flex", width: "100%", textDecoration: "none" }}>
							<EmailIcon style={{ color: theme.palette.primary.main, marginRight: 2 }} />
							<CustomText title={store_data.store_email} color={theme.palette.primary.main} fontSize={13} />
						</Link>
					</MenuItem>
				) : (
					""
				)}
				<MenuItem onClick={handleCloseCustomer}>
					<Link to={"/referrals-register"} style={{ display: "inline-flex", width: "100%", textDecoration: "none" }}>
						<AddBusinessIcon style={{ color: theme.palette.primary.main, marginRight: 2 }} />
						<CustomText title={store_data.translations["translate-all-pages-body-27"]} color={theme.palette.primary.main} fontSize={13} />
					</Link>
				</MenuItem>
				<Divider />
				<CustomText title={store_data.translations["translate-all-pages-footer-5"]} textTransform="uppercase" color={theme.palette.primary.main} fontFamily="ClashDisplay-SemiBold" textAlign="center" fontSize={20} />
				<MenuItem onClick={handleCloseCustomer}>
					<CustomText title="+1 321 450 9305" color={theme.palette.primary.main} fontSize={13} />
				</MenuItem>
				<MenuItem onClick={handleCloseCustomer}>
					<CustomText title="customers@ventadirekta.com" color={theme.palette.primary.main} fontSize={13} />
				</MenuItem>
			</StyledMenu>
		</>
	);
}
