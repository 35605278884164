import React, { useContext, useState, useEffect } from "react";
import { useTheme } from "@mui/material/styles";
import { Box } from "@mui/material";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import Badge from "@mui/material/Badge";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import CustomText from "../../Common/CustomText/CustomText";
import { colors } from "../../../Utils/Colors";
import {} from "../../../Assets/Style/Drawer.css";
import { icons } from "../../../Assets/Icons";
import Spacer from "../../Common/Spacer/Spacer";
import { Link, useParams } from "react-router-dom";
import IconTint from "react-icon-tint";

import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuList from "@mui/material/MenuList";
import MenuItem from "@mui/material/MenuItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import DownloadIcon from "@mui/icons-material/Download";

import { StoreContext, LanguageContext } from "../../../Context/storeContext";

export const MuiDrawer = (props) => {
	const theme = useTheme();
	const store_data = useContext(StoreContext);
	let collections_data = JSON.parse(store_data.collections);
	const [mouseHover, setMouseHover] = useState(false);
	const url = useParams();

	useEffect(() => {
		props.setIsDrawerOpen(false);
	}, [url]);

	return (
		<SwipeableDrawer anchor="right" open={props.isDrawerOpen} sx={{}} onClose={() => props.setIsDrawerOpen(false)} onOpen={() => props.setIsDrawerOpen(true)}>
			<Box p={2} role="presentation" textAlign="center" backgroundColor={theme.palette.primary.main} height={"100%"} padding={0} width={350}>
				{window.innerWidth <= 700 ? <MobileTop /> : <WebTop setIsDrawerOpen={props.setIsDrawerOpen} />}
				<Spacer height={30} />
				<List style={{ backgroundColor: theme.palette.primary.main }}>
					{collections_data.map((item, index) => (
						<Link key={"/" + item.handle} to={"/collection/" + item.handle} style={{ textDecoration: "none" }}>
							<ListItem className="main mainwidth" style={{ paddingLeft: 40 }} key={index} disablePadding>
								<ListItemButton>
									<ListItemText primary={<CustomText title={item.name} fontSize={15} color={colors.white} />} />
								</ListItemButton>
							</ListItem>
						</Link>
					))}
					<Link to={"/new-arrivals"} style={{ textDecoration: "none" }}>
						<ListItem className="main mainwidth" style={{ paddingLeft: 40 }} disablePadding>
							<ListItemButton>
								<ListItemText primary={<CustomText title={store_data.translations["translate-home-body-19"]} fontFamily="ClashDisplay-SemiBold" fontSize={15} color={colors.white} />} />
							</ListItemButton>
						</ListItem>
					</Link>
					<Footer setIsDrawerOpen={props.setIsDrawerOpen} />
					{/* {window.innerWidth <= 775 ? <Footer setIsDrawerOpen={props.setIsDrawerOpen} /> : ""} */}
				</List>
			</Box>
		</SwipeableDrawer>
	);
};

const MobileTop = () => {
	const theme = useTheme();
	const store_data = useContext(StoreContext);
	const { language, setLanguage } = useContext(LanguageContext);

	const [anchorEl, setAnchorEl] = React.useState(null);
	const open = Boolean(anchorEl);
	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	const [cartLength, setcartLength] = useState(
		JSON.parse(localStorage.getItem("cart")).length > 0
			? JSON.parse(localStorage.getItem("cart"))
					.map((item) => item.quantity)
					.reduce((a, b) => a + b)
			: 0
	);

	window.addEventListener("storage", (e) => {
		setcartLength(
			JSON.parse(localStorage.getItem("cart")).length > 0
				? JSON.parse(localStorage.getItem("cart"))
						.map((item) => item.quantity)
						.reduce((a, b) => a + b)
				: 0
		);
	});

	return (
		<>
			<Spacer height={20} />
			<div
				style={{
					display: "flex",
					justifyContent: "space-between",
					width: "auto",
					paddingRight: 50,
					paddingLeft: 50,
				}}>
				<div>
					<CustomText title={store_data.subdomain} color={theme.palette.text.secondary} />
				</div>
				<div>
					<Button id="basic-button" padding={0} aria-controls={open ? "basic-menu" : undefined} aria-haspopup="true" aria-expanded={open ? "true" : undefined} onClick={handleClick}>
						<img src={`https://admin.ventadirekta.com${store_data.current_language.flag}`} alt="" width={30} height={30} />
					</Button>
					<Link to="/cart" style={{ color: colors.colorB }}>
						<Badge badgeContent={cartLength} color="secondary" variant="solid">
							<ShoppingCartOutlinedIcon />
						</Badge>
					</Link>
				</div>
			</div>
			<Menu
				id="basic-menu"
				anchorEl={anchorEl}
				open={open}
				onClose={handleClose}
				MenuListProps={{
					"aria-labelledby": "basic-button",
				}}>
				<MenuList>
					{JSON.parse(store_data.languages).map((item, index) => {
						return (
							<MenuItem
								key={index}
								onClick={(e) => {
									setLanguage(item.code);
								}}>
								<ListItemIcon>
									<img src={`https://admin.ventadirekta.com${item.flag}`} alt="" width={30} height={30} />
								</ListItemIcon>
								<ListItemText>{item.name}</ListItemText>
							</MenuItem>
						);
					})}
				</MenuList>
			</Menu>
		</>
	);
};
// Web Footer

const WebTop = (props) => {
	const store_data = useContext(StoreContext);
	const { language, setLanguage } = useContext(LanguageContext);

	const [anchorEl, setAnchorEl] = React.useState(null);
	const open = Boolean(anchorEl);
	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};
	return (
		<div>
			<div
				style={{
					marginTop: 65,
					display: "flex",
					padding: "0 40px",
					justifyContent: "space-between",
					alignItems: "center",
				}}>
				<Button id="basic-button" padding={0} aria-controls={open ? "basic-menu" : undefined} aria-haspopup="true" aria-expanded={open ? "true" : undefined} onClick={handleClick}>
					<img src={`https://admin.ventadirekta.com${store_data.current_language.flag}`} alt="" width={30} height={30} />
				</Button>
				<img src={icons.cross} height={13} width={13} color={colors.black} onClick={() => props.setIsDrawerOpen(false)} alt="" style={{ cursor: "pointer" }} />
			</div>
			<Menu
				id="basic-menu"
				anchorEl={anchorEl}
				open={open}
				onClose={handleClose}
				MenuListProps={{
					"aria-labelledby": "basic-button",
				}}>
				<MenuList>
					{JSON.parse(store_data.languages).map((item, index) => {
						return (
							<MenuItem
								key={index}
								onClick={(e) => {
									setLanguage(item.code);
								}}>
								<ListItemIcon>
									<img src={`https://admin.ventadirekta.com${item.flag}`} alt="" width={30} height={30} />
								</ListItemIcon>
								<ListItemText>{item.name}</ListItemText>
							</MenuItem>
						);
					})}
				</MenuList>
			</Menu>
		</div>
	);
};

// Mobile Footer

const Footer = (props) => {
	const store_data = useContext(StoreContext);
	const theme = useTheme();

	return (
		<>
			<Spacer height={150} />
			<div
				style={{
					display: "flex",
					justifyContent: "space-around",
					alignItems: "center",
				}}>
				<div
					style={{
						display: "flex",
						flexDirection: "column",
						textAlign: "left",
					}}>
					<Link to="https://ventadirekta.com/landing/privacy-politics" target="_blank" rel="noopener noreferrer" style={{ textDecoration: "none", textAlign: "left" }}>
						<CustomText title={`${store_data.translations["translate-all-pages-footer-3"]} `} color={theme.palette.text.secondary} fontSize={13} />
					</Link>
					<Link to="https://ventadirekta.com/landing/terms-conditions" target="_blank" rel="noopener noreferrer" style={{ textDecoration: "none", textAlign: "left" }}>
						<CustomText title={` ${store_data.translations["translate-all-pages-footer-4"]} `} color={theme.palette.text.secondary} fontSize={13} />
					</Link>
					<Link to="https://ventadirekta.com/landing/returns-changes" target="_blank" rel="noopener noreferrer" style={{ textDecoration: "none", textAlign: "left" }}>
						<CustomText title={`${store_data.translations["translate-all-pages-footer-5"]} `} color={theme.palette.text.secondary} fontSize={13} />
					</Link>
				</div>
				<div>
					<IconTint src={icons.headset} color={"#ffff"} style={{ height: 20, width: 20 }} />
					{/* <img src={icons.headset} height={20} width={20} /> */}
				</div>
			</div>
			<Spacer height={60} />
			<div style={{ display: "flex", justifyContent: "space-around", alignItems: "center" }}>
				<Link
					to="/download"
					style={{
						textDecoration: "none",
						display: "inline-flex",
					}}>
					<DownloadIcon style={{ color: "#fff", marginRight: 2 }} />
					<CustomText title="Download Store App " color={theme.palette.text.secondary} fontSize={13} />
				</Link>
			</div>
			<Spacer height={60} />
			<div
				style={{
					display: "flex",
					justifyContent: "space-around",
					alignItems: "center",
				}}>
				<div style={{ display: "flex", flexDirection: "column" }}>
					<CustomText title="Ventadirekta © 2021. All Rights Reserved" color={colors.white} fontSize={10} />
				</div>
				<div>
					<img onClick={() => props.setIsDrawerOpen(false)} src={icons.cross} height={13} width={13} />
				</div>
			</div>
		</>
	);
};
