import React, { useState, useContext } from "react";

import "../../../Assets/Style/Navbar.css";
import "../../../Assets/Style/Body.css";
import { center, row } from "../../../Utils/CommonStyles";
import { colors } from "../../../Utils/Colors";
import CustomText from "../CustomText/CustomText";
import SearchComponent from "../SearchComponent/SearchComponent";

import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import Badge from "@mui/material/Badge";
import { Link } from "react-router-dom";
import { Grid } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuList from "@mui/material/MenuList";
import MenuItem from "@mui/material/MenuItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";

import { StoreContext, LanguageContext } from "../../../Context/storeContext";

import {} from "./style.css";

export const NavWeb = (props) => {
	const theme = useTheme();
	const store_data = useContext(StoreContext);
	const { language, setLanguage } = useContext(LanguageContext);
	let collections_data = JSON.parse(store_data.collections);

	const [anchorEl, setAnchorEl] = React.useState(null);
	const open = Boolean(anchorEl);
	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	const [cartLength, setcartLength] = useState(
		JSON.parse(localStorage.getItem("cart")).length > 0
			? JSON.parse(localStorage.getItem("cart"))
					.map((item) => item.quantity)
					.reduce((a, b) => a + b)
			: 0
	);

	window.addEventListener("storage", (e) => {
		setcartLength(
			JSON.parse(localStorage.getItem("cart")).length > 0
				? JSON.parse(localStorage.getItem("cart"))
						.map((item) => item.quantity)
						.reduce((a, b) => a + b)
				: 0
		);
	});

	return (
		<>
			<Grid className="navBarGeneral nav-btns desk" container rowSpacing={{ xs: 5, sm: 2, md: 3 }} columnSpacing={{}} style={{}}>
				<Grid
					justifyContent={"space-between"}
					container
					rowSpacing={{ xs: 5, sm: 2, md: 3 }}
					style={{
						backgroundColor: theme.palette.background.default,
						paddingTop: 0,
						marginTop: 0,
						height: window.innerWidth <= 640 ? "8vh" : "",
					}}>
					<Grid
						item
						style={{
							paddingLeft: 40,
							paddingTop: 0,
							backgroundColor: theme.palette.background.default,
							display: "inline-flex",
							alignItems: "center",
						}}>
						<Link to="/" style={{ textDecoration: "none" }}>
							<CustomText title={store_data.store_name} fontSize={18} textAlign={"left"} fontFamily="ClashDisplay-SemiBold" textTransform="capitalize" />
						</Link>
					</Grid>
					<Grid
						item
						style={{
							paddingLeft: 40,
							paddingTop: 0,
							backgroundColor: theme.palette.background.default,
							display: "inline-flex",
							alignItems: "center",
							justifyContent: "flex-end",
						}}>
						<ul className="nav-btns-list">
							{collections_data.map((item, index) => {
								return (
									<li key={index}>
										<Link
											to={`/collection/${item.handle}`}
											key={item.handle}
											style={{
												fontSize: 15,
												fontFamily: item.active ? "ClashDisplay-Semibold" : "ClashDisplay-Regular",
											}}>
											{item.name}
										</Link>
									</li>
								);
							})}
						</ul>
					</Grid>
					<Grid
						item
						style={{
							paddingRight: 20,
							display: "inline-flex",
							alignItems: "center",
							justifyContent: "right",
							paddingTop: "10px",
						}}>
						<div style={{ ...row, padding: 5, ...center }}>
							<SearchComponent width={200} />
						</div>
						<div
							style={{
								...center,
							}}>
							<Button id="basic-button" aria-controls={open ? "basic-menu" : undefined} aria-haspopup="true" aria-expanded={open ? "true" : undefined} onClick={handleClick}>
								<img src={`https://admin.ventadirekta.com${store_data.current_language.flag}`} alt="" width={30} height={30} />
							</Button>

							<Link to="/cart" style={{ color: colors.colorB }}>
								{/* <CssVarsProvider> */}
								<Badge badgeContent={cartLength} color="primary" variant="solid">
									<ShoppingCartOutlinedIcon />
								</Badge>
								{/* </CssVarsProvider> */}
							</Link>
							{/* <ToggleSwitch light={props.light} setLight={props.setLight} /> */}
						</div>
					</Grid>
				</Grid>
			</Grid>
			<Menu
				id="basic-menu"
				anchorEl={anchorEl}
				open={open}
				onClose={handleClose}
				MenuListProps={{
					"aria-labelledby": "basic-button",
				}}>
				<MenuList>
					{JSON.parse(store_data.languages).map((item, index) => {
						return (
							<MenuItem key={index} onClick={(e) => {
								setLanguage(item.code);
							}}>
								<ListItemIcon>
									<img src={`https://admin.ventadirekta.com${item.flag}`} alt="" width={30} height={30} />
								</ListItemIcon>
								<ListItemText>
									{item.name}
								</ListItemText>
							</MenuItem>
						);
					})}
				</MenuList>
			</Menu>
		</>

		// 	<Link to="/" style={{ textDecoration: "none" }}>
		// 		<CustomText fontSize={18} title={store_data.subdomain} textAlign={"left"} fontFamily={"ClashDisplay-SemiBold"} />
		// 	</Link>
		// 	{/* <CustomText title="STORE" /> */}
		// 	<Item
		// 		sx={{
		// 			flexGrow: 1.9,
		// 			boxShadow: "none",
		// 			display: "flex",
		// 			flexDirection: "row",
		// 			paddingTop: 0,
		// 			paddingBottom: 0,
		// 			justifyContent: "flex-end",
		// 			cursor: "pointer",
		// 			position: "sticky",
		// 			backgroundColor: theme.palette.background.default,
		// 		}}>
		// 		<ul className="nav-btns-list">
		// 			{collections_data.map((item, index) => {
		// 				return (
		// 					<li key={index}>
		// 						<Link
		// 							to={`/collection/${item.handle}`}
		// 							key={item.handle}
		// 							style={{
		// 								fontSize: 15,
		// 								fontFamily: item.active ? "ClashDisplay-Semibold" : "ClashDisplay-Regular",
		// 							}}>
		// 							{item.name}
		// 						</Link>
		// 					</li>
		// 				);
		// 			})}
		// 		</ul>
		// 	</Item>
		// 	<Item
		// 		sx={{
		// 			padding: 0,
		// 			flexGrow: 0.1,
		// 			boxShadow: "none",
		// 			display: "flex",
		// 			flexDirection: "row",
		// 			alignSelf: "center",
		// 			position: "relative",
		// 			backgroundColor: colors.white,
		// 			position: "sticky",
		// 			backgroundColor: theme.palette.background.default,
		// 		}}
		// 		onClick={() => {
		// 			setOpen(!open);
		// 		}}>
		// 		<SearchComponent width={200} setOpen={setOpen} open={open} />
		// 	</Item>

		// 	{/* <ToggleSwitch light={props.light} setLight={props.setLight} /> */}
		// 	<Item
		// 		sx={{
		// 			flexGrow: 0,
		// 			boxShadow: "none",
		// 			display: "flex",
		// 			flexDirection: "row",
		// 			alignSelf: "center",
		// 			position: "sticky",
		// 			backgroundColor: theme.palette.background.default,
		// 		}}>
		// 		<Link to="/cart" style={{ color: colors.colorB }}>
		// 			{/* <CssVarsProvider> */}
		// 			<Badge badgeContent={cartLength} color="primary" variant="solid">
		// 				<ShoppingCartOutlinedIcon />
		// 			</Badge>
		// 			{/* </CssVarsProvider> */}
		// 		</Link>
		// 	</Item>
		// </Grid>
	);
};
