import "./style.css";
import "../../Assets/Style/font.css";
import React, { useState, useContext } from "react";
import { Helmet } from "react-helmet-async";
import { useParams, Link } from "react-router-dom";
import { NewArrivalsWeb } from "../../Components/Pages/NewArrivals/Web/index";
import NewArrivalsMobile from "../../Components/Pages/NewArrivals/Mobile/index";
import { StoreContext } from "../../Context/storeContext";

export const NewArrivals = () => {
	let urlParams = useParams();
	const store_data = useContext(StoreContext);
	const [open, setOpen] = useState(false);
	const [light, setLight] = useState(true);
	const [on, setOn] = useState(false);
	const [isDrawerOpen, setIsDrawerOpen] = React.useState(false);

	return (
		<div>
			<Helmet>
				<title>New Arrivals</title>
			</Helmet>
			{window.innerWidth <= 1024 ? <NewArrivalsMobile isDrawerOpen={isDrawerOpen} setIsDrawerOpen={setIsDrawerOpen} on={on} light={light} setLight={setLight} /> : <NewArrivalsWeb isDrawerOpen={isDrawerOpen} setIsDrawerOpen={setIsDrawerOpen} on={on} light={light} setLight={setLight} />}
		</div>
	);
};
