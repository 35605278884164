import React, { useContext } from "react";
import CustomText from "../CustomText/CustomText";
import { Link } from "react-router-dom";
import Spacer from "../Spacer/Spacer";
import { useTheme } from "@mui/material/styles";
import { Grid } from "@mui/material";

import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import EmailIcon from "@mui/icons-material/Email";
import AddBusinessIcon from '@mui/icons-material/AddBusiness';
import DownloadIcon from '@mui/icons-material/Download';

import Visa from "../../../Assets/Resources/Payments/visa.png";
import AmericanExpress from "../../../Assets/Resources/Payments/american-express.png";
import Mastercard from "../../../Assets/Resources/Payments/mastercard.png";
import Paypal from "../../../Assets/Resources/Payments/paypal.png";

import { StoreContext } from "../../../Context/storeContext";

const validProductionAdminUrl = function (url = '') {
	if (process.env.REACT_APP_NODE_ENV === "TEST") {
		return `http://localhost:3030${ url }`;
	} else {
		return `https://admin.ventadirekta.com${ url }`;
	}
};

const Footer = () => {
	const theme = useTheme();
	const store_data = useContext(StoreContext);
	return (
		<footer>
			<Grid
				container
				direction="row"
				justifyContent="center"
				alignItems="flex-start"
				style={{ padding: "30px 50px", backgroundColor: theme.palette.text.primary }}>
				<Grid item xs={12} sm={4}>
					<CustomText title={store_data.translations["translate-all-pages-footer-2"]} textTransform="uppercase" color={theme.palette.text.secondary} fontSize={13} />
					<Spacer height={10} />
					<div
						style={{
							display: "flex",
							flexWrap: "wrap",
						}}>
						<Link
							to="https://ventadirekta.com/landing/privacy-politics"
							target="_blank"
							rel="noopener noreferrer"
							style={{
								textDecoration: "none",
							}}>
							<CustomText title={`${store_data.translations["translate-all-pages-footer-3"]} /`} color={theme.palette.text.secondary} fontSize={13} />
						</Link>
						<Link
							to="https://ventadirekta.com/landing/terms-conditions"
							target="_blank"
							rel="noopener noreferrer"
							style={{
								textDecoration: "none",
							}}>
							<CustomText title={` ${store_data.translations["translate-all-pages-footer-4"]} / `} color={theme.palette.text.secondary} fontSize={13} />
						</Link>
						<Link
							to="https://ventadirekta.com/landing/returns-changes"
							target="_blank"
							rel="noopener noreferrer"
							style={{
								textDecoration: "none",
							}}>
							<CustomText title={`${store_data.translations["translate-all-pages-footer-5"]} `} color={theme.palette.text.secondary} fontSize={13} />
						</Link>
					</div>
					<Spacer height={30} />
					<CustomText title="+1 321 450 9305" color={theme.palette.text.secondary} fontSize={13} />
					<CustomText title="customers@ventadirekta.com" color={theme.palette.text.secondary} fontSize={13} />
					<Spacer height={30} />
					<Link
						to="/download"
						style={{
							textDecoration: "none",
							display: "inline-flex", width: "100%"
						}}>
							<DownloadIcon style={{ color: "#fff", marginRight: 2 }} />
							<CustomText title="Download Store App " color={theme.palette.text.secondary} fontSize={13} />
					</Link>
				</Grid>
				<Grid item xs={12} sm={4}>
					<Spacer height={window.innerWidth <= 425 ? 20 : ''} />
					<CustomText title={store_data.translations["translate-all-pages-footer-10"]} textTransform="uppercase" color={theme.palette.text.secondary} fontSize={13} />
					<Spacer height={10} />
					{store_data.whatsapp ? (
						<Link to={`https://wa.me/${store_data.store_phone_link}`} style={{ display: "inline-flex", width: "100%", textDecoration: "none" }}>
							<WhatsAppIcon style={{ color: "#fff", marginRight: 2 }} />
							<CustomText title={store_data.store_phone_link} color={theme.palette.text.secondary} fontSize={13} />
						</Link>
					) : (
						""
					)}
					{store_data.facebook ? (
						<Link to={store_data.facebook} style={{ display: "inline-flex", width: "100%", textDecoration: "none" }}>
							<FacebookIcon style={{ color: "#fff", marginRight: 2 }} />
							<CustomText title={"Facebook"} color={theme.palette.text.secondary} fontSize={13} />
						</Link>
					) : (
						""
					)}
					{store_data.twitter ? (
						<Link to={store_data.twitter} style={{ display: "inline-flex", width: "100%", textDecoration: "none" }}>
							<TwitterIcon style={{ color: "#fff", marginRight: 2 }} />
							<CustomText title={"Twitter"} color={theme.palette.text.secondary} fontSize={13} />
						</Link>
					) : (
						""
					)}
					{store_data.instagram ? (
						<Link to={store_data.instagram} style={{ display: "inline-flex", width: "100%", textDecoration: "none" }}>
							<InstagramIcon style={{ color: "#fff", marginRight: 2 }} />
							<CustomText title={"Instagram"} color={theme.palette.text.secondary} fontSize={13} />
						</Link>
					) : (
						""
					)}
					{store_data.store_email ? (
						<Link to={`mailto:${store_data.store_email}`} style={{ display: "inline-flex", width: "100%", textDecoration: "none" }}>
							<EmailIcon style={{ color: "#fff", marginRight: 2 }} />
							<CustomText title={store_data.store_email} color={theme.palette.text.secondary} fontSize={13} />
						</Link>
					) : (
						""
					)}
					<Link to={"/referrals-register"} style={{ display: "inline-flex", width: "100%", textDecoration: "none" }}>
						<AddBusinessIcon style={{ color: "#fff", marginRight: 2 }} />
						<CustomText title={"Crea tu propia tienda"} color={theme.palette.text.secondary} fontSize={13} />
					</Link>
				</Grid>
				<Grid item xs={12} sm={4}>
				<Spacer height={window.innerWidth <= 425 ? 20 : ''} />
					<CustomText title={store_data.translations["translate-home-body-1"]} textTransform="uppercase" color={theme.palette.text.secondary} fontSize={13} />
					<Spacer height={10} />
					<Grid container direction="row" justifyContent="center" alignItems="center">
						<Grid item xs={6}>
							<div style={{ backgroundColor: "#fff", marginBottom: 10, width: 80, height: 60, display: "inline-flex", justifyContent: "center", alignItems: "center", borderRadius: 5 }}>
								<img src={Visa} alt="Visa" width={"60%"} height={"auto"} />
							</div>
						</Grid>
						<Grid item xs={6}>
							<div style={{ backgroundColor: "#fff", marginBottom: 10, width: 80, height: 60, display: "inline-flex", justifyContent: "center", alignItems: "center", borderRadius: 5 }}>
								<img src={AmericanExpress} alt="American Express" width={"60%"} height={"auto"} />
							</div>
						</Grid>
						<Grid item xs={6}>
							<div style={{ backgroundColor: "#fff", marginBottom: 10, width: 80, height: 60, display: "inline-flex", justifyContent: "center", alignItems: "center", borderRadius: 5 }}>
								<img src={Mastercard} alt="Mastercard" width={"60%"} height={"auto"} />
							</div>
						</Grid>
						<Grid item xs={6}>
							<div style={{ backgroundColor: "#fff", marginBottom: 10, width: 80, height: 60, display: "inline-flex", justifyContent: "center", alignItems: "center", borderRadius: 5 }}>
								<img src={Paypal} alt="Paypal" width={"60%"} height={"auto"} />
							</div>
						</Grid>
					</Grid>
				</Grid>
				<Grid item xs={12}>
					<Spacer height={20} />
					<CustomText title={store_data.subdomain} color={theme.palette.text.secondary} fontFamily="ClashDisplay-SemiBold" textAlign="center" />
				</Grid>
				<Grid item xs={12}>
					<Spacer height={10} />
					<CustomText title={"Ventadirekta © 2023. All Rights Reserved"} fontSize={15} color={theme.palette.text.secondary} fontFamily="ClashDisplay-Regular" textAlign="center" />
					<Spacer height={window.innerWidth <= 425 ? 40 : ''} />
				</Grid>
			</Grid>
		</footer>
	);
};

export default Footer;
