import { useState, useEffect, createContext } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import { ThemeProvider, StyledEngineProvider } from "@mui/material/styles";
import { createTheme } from "@mui/material/styles";
import {colorPalette} from "../Themes/store"
import {} from "./style.css";

export const StoreContext = createContext();
export const LanguageContext = createContext();
let cartItems = localStorage.getItem("cart") ? JSON.parse(localStorage.getItem("cart")) : localStorage.setItem("cart", "[]");

if (!localStorage.getItem("cart_id") || localStorage.getItem("cart_id") === "undefined") {
	localStorage.setItem("cart_id", "no-cart");
}

export function StoreContextProvider(props) {
	const [load, setLoad] = useState(false);
	const [store, setStore] = useState();
	const [firstLoad, setFirstLoad] = useState(false);
	const [error, setError] = useState(false);

	const [language, setLanguage] = useState(null);
	const [theme, setTheme] = useState(0);
	const lang_value = { language, setLanguage };

	const appTheme = createTheme(colorPalette[theme])

	useEffect(() => {
		if (firstLoad === false) {
			getData(language);
		} else {
			const intervalCall = setInterval(() => {
				getData(language);
			}, 300000);
			return () => {
				clearInterval(intervalCall);
			};
		}
	}, [firstLoad, language]);

	useEffect(() => {
		if (firstLoad === true) {
			setLoad(false);
			getData(language);
		}
	}, [language]);

	const validProductionAdminUrl = function (url = '') {
		if (process.env.REACT_APP_NODE_ENV === "TEST") {
			return `http://localhost:3030${ url }`;
		} else {
			return `https://admin.ventadirekta.com${ url }`;
		}
	};

	const getData = async (language) => {

		try {
			const res = await fetch(
				validProductionAdminUrl(`/api/v1/store/${language}`)
			);
			const store_result = await res.json();
			
			let products_api = store_result.collections_all.map((item) => item.sort_products);
			let products = [];

			for (const product_array of products_api.map((item) => item)) {
				products.push(...product_array);
			}

			products = products.filter((value, index, self) => index === self.findIndex((t) => t.place === value.place && t._id === value._id));
			const sortByCollection = store_result.collections_positions.reduce((obj, item, index) => {
				return {
					...obj,
					[item]: index,
				};
			}, {});
			store_result.collections_all = store_result.collections_all.sort((a, b) => sortByCollection[a.shopify_id] - sortByCollection[b.shopify_id]);
			store_result.collections = JSON.stringify(
				JSON.parse(store_result.collections)
					.sort((a, b) => sortByCollection[a.shopify_id] - sortByCollection[b.shopify_id])
			);

			store_result.filter_products = products;
			store_result.best_sellers_products = products.filter(product => store_result.best_sellers_products.includes(product.shopify_id));
			store_result.cart_id = "no-cart";

			setStore(store_result);
			setTheme(parseInt(store_result.theme_color.name.split('_')[1]) - 1);
			setError(false);
			setFirstLoad(true);
		} catch (e) {
			console.log(e);
			setError(true);
		}
		setLoad(true);
	};

	if (load && store && error == false) {
		return (
			<StoreContext.Provider value={store}>
				<LanguageContext.Provider value={lang_value}>
					<ThemeProvider theme={appTheme}>
						{props.children}
					</ThemeProvider>
				</LanguageContext.Provider>
			</StoreContext.Provider>
		);
	} else if (load && store && error == true) {
		return (
			<StoreContext.Provider value={store}>
				<LanguageContext.Provider value={lang_value}>
				<ThemeProvider theme={appTheme}>
					{error ? (
						<div className="context-error">
							<p>Parece que no tienes internet u ocurrió un error</p>
							<CircularProgress style={{ color: "#fff" }} />
						</div>
					) : (
						""
					)}
					{props.children}
				</ThemeProvider>
				</LanguageContext.Provider>
			</StoreContext.Provider>
		);
	} else {
		return (
			<div className="landing-pre-loader-div">
				<div className="landing-pre-loader">
					<img src="https://ventadirekta.com/assets/images/logo/logo-ventadirekta.webp" alt="" />
				</div>
			</div>
		);
	}
}
