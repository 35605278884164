export const colors = {
  primary: "#",
  secondary: "#CA5DFF",
  pinkish: "#EF5DA8",
  // purple:"#AEAFF7",
  // lightGreen:"#A0E3E2",
  orange: "#F09E54",
  orangeLight: "#FFC97E",
  parrot: "#C3F2A6",
  white: "#FFFFFF",
  pureWite: "#eee",
  black: "#1B1B1B",
  red: "red",
  grey: "#686868",
  lightBlack: "#343434",
  lightGray: "#8E8E93",
  darkGray: "#343a40",
  tabColor: "#DFD2FD",
  lightestGrey: "#EAEAEA",
  lightPurple: "#C99FFF",

  // green:"#1DC3B1",
  // lightGreen:"#f2ffff",
  // purple:"#CA5DFF",

  // master
  colorA: "#ffffff",
  colorB: "#000000",
  colorC: "#8E8E93",


// PinkishRed
//   colorA:"#F7EFED",
//     colorB:"#E7486F",
//     colorC:"#39A1BD",


// magenta
//   colorA: "#FDE3D0",
//   colorB: "#884384",
//   colorC: "#FF720E",


// pinkishpurple
//    colorA:"#f2ffff",
//     colorB:"#CA5DFF",
//     colorC:"#1DC3B1",


// redbrown
//  colorA:"#F9F2EC",
//     colorB:"#C16565",
//     colorC:"#542E2E",

// reddishmagenta
//   colorA:"#FFF9FE",
//     colorB:"#D84EA9",
//     colorC:"#EFB733",
};
