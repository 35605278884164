import React, { useEffect, useState, useContext } from "react";
import { alpha, styled } from "@mui/material/styles";
import { useTheme } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import FormGroup from "@mui/material/FormGroup";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Autocomplete from "@mui/material/Autocomplete";
import InputAdornment from "@mui/material/InputAdornment";
import CircularProgress from "@mui/material/CircularProgress";
import { Helmet } from "react-helmet-async";
import { toast } from "react-toastify";

import {} from "./style.css";

const CssTextField = styled(TextField)(({ theme }) => ({
	"& label.MuiFormLabel-root": {
		// color: theme.palette.primary.main,
		color: "#000",
	},
}));
const styleModal = {
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	width: "80vw",
	height: "70vh",
	bgcolor: "background.paper",
	border: "none",
	boxShadow: 24,
	p: 4,
	overflowY: "auto",
};

export const Referral = () => {
	const theme = useTheme();
	const [phoneCodes, setPhoneCodes] = useState();
	const [languages, setLanguages] = useState();
	const [collections, setCollections] = useState();
	const [themes, setThemes] = useState();
	const [load, setLoad] = useState(false);

	const [dataLanguage, setDataLanguage] = useState();
	const [dataTheme, setDataTheme] = useState();

	const [storeName, setStoreName] = useState();
	const [storeNameValid, setStoreNameValid] = useState(null);
	const [whatsappCheck, setWhatsappCheck] = useState(false);
	const [companyRadio, setCompanyRadio] = useState("no");
	const [socialRadio, setSocialRadio] = useState();
	const [password, setPassword] = useState();
	const [passwordConfirmation, setPasswordConfirmation] = useState();

	const [open, setOpen] = useState(false);
	const handleOpen = () => setOpen(true);
	const handleClose = () => setOpen(false);

	const validProductionAdminUrl = function (url = '') {
		if (process.env.REACT_APP_NODE_ENV === "TEST") {
			return `http://localhost:3030${ url }`;
		} else {
			return `https://admin.ventadirekta.com${ url }`;
		}
	};

	const verifyStore = async (value) => {
		try {
			const result = await fetch(validProductionAdminUrl('/api/v1/verify-subdomain'), {
				method: "POST",
				headers: {
					Accept: "application/json",
					"Content-Type": "application/json",
				},
				body: JSON.stringify({
					store_name: storeName,
				}),
			});
			const verify_result = await result.json();
			setStoreNameValid(verify_result.data.valid);
		} catch (e) {
			console.log(e);
		}
	};
	const handleStoreName = (event) => {
		setStoreName(event.target.value.trim());
		setStoreNameValid(null);
	};
	const handleWhatsappCheck = (event) => {
		setWhatsappCheck(event.target.checked);
	};
	const handleCompanyRadio = (event) => {
		setCompanyRadio(event.target.value);
	};
	const handleSocialRadio = (event) => {
		setSocialRadio(event.target.value);
	};
	const handlePassword = (event) => {
		setPassword(event.target.value);
	};
	const handlePasswordConfirmation = (event) => {
		setPasswordConfirmation(password === event.target.value ? true : false);
	};
	const getURLParameter = function (parameter_name) {
		let url_page = window.location.search.substring(1);
		let url_variables = url_page.split("&");
		for (const item_data of url_variables) {
			let parameter_url = item_data.split("=");
			if (parameter_url[0] == parameter_name) {
				return decodeURIComponent(parameter_url[1]);
			}
		}
	};
	const handleSubmit = async (event) => {
		event.preventDefault();
		const phone_code = event.target.phone_code.value;
		const phone_store_code = event.target.phone_store_code.value;

		let data = {
			first_name: event.target.first_name.value,
			last_name: event.target.last_name.value,
			phone: `${phone_code}${event.target.phone.value}`,
			email: event.target.email.value,
			password: event.target.password.value,
			password_confirmation: event.target.password_confirmation.value,
			language: event.target.language.value === "Spanish" ? "es-CO" : "en-US",
			card_type: "",
			card_number: "",
			card_code: "",
			card_date: "",
			card_name: "",
			card_zip_code: "",
			company: companyRadio === "yes" ? event.target.company_name.value : "",
			tax_id_eni: companyRadio === "yes" ? event.target.tax_id.value : "",
			country: event.target.country.value,
			province: event.target.province.value,
			city: event.target.city.value,
			zip_code: event.target.zip_code.value,
			address_line1: event.target.address_line1.value,
			address_line2: event.target.address_line2.value,
			store_name: event.target.store_name.value,
			store_name_initials: event.target.store_name_initials.value,
			store_phone: `${phone_store_code}${event.target.store_phone.value}`,
			whatsapp: whatsappCheck ? `https://api.whatsapp.com/send?phone=+${phone_store_code}${event.target.store_phone.value}` : "",
			store_email: event.target.store_email.value,
			paypal_email: event.target.paypal_email.value,
			facebook: "",
			twitter: "",
			instagram: "",
			theme_color: event.target.theme_color.value,
			collection_style: "grid",
			social_network_advertising: socialRadio === "Others" ? event.target.social_media.value : "",
			social_network_search: socialRadio === "Others" ? "" : socialRadio,
			record_source: getURLParameter("utm_source") ? getURLParameter("utm_source") : "web",
			content_creator_code: getURLParameter("cc_code") ? getURLParameter("cc_code") : "",
		};

		try {
			const result = await fetch( validProductionAdminUrl("/users/save-store/"), {
				method: "POST",
				headers: {
					Accept: "application/json",
					"Content-Type": "application/json",
				},
				body: JSON.stringify(data),
			});
			const submit_result = await result.json();
			console.log(submit_result);

			if (submit_result.success.show === true) {
				let type = submit_result.success.type;
				toast[type](submit_result.success.message);
			}
			if (submit_result.success.type === "success") {
				data.utm_source = getURLParameter("utm_source") ? getURLParameter("utm_source") : "web";
				data.utm_medium = getURLParameter("utm_medium") ? getURLParameter("utm_medium") : "landing";
				data.utm_campaign = getURLParameter("utm_campaign") ? getURLParameter("utm_campaign") : "";
				data.country_store = "US";

				try {
					const s2f = await fetch(`https://s2f.2becommerce.com/vtd/form`, {
						method: "POST",
						headers: {
							Accept: "application/json",
							"Content-Type": "application/json",
						},
						body: JSON.stringify(data),
					});
					const s2f_result = await s2f.json();
					window.location.replace(`https://ventadirekta.com/thank-you-for-register`);
				} catch (e) {
					console.log(e);
				}
			}
		} catch (e) {
			console.log(e);
		}

		console.log(data);
	};

	useEffect(() => {
		getData();
	}, []);

	const getData = async () => {
		try {
			const phone_codes = await fetch(validProductionAdminUrl('/get/all-countries-provinces-phone-codes-react/'));
			const languages = await fetch(validProductionAdminUrl("/languages/list"));
			const collections = await fetch(validProductionAdminUrl("/collections/list-collections-store/"));
			const themes = await fetch(validProductionAdminUrl("/store-themes/list-show/"));

			const phone_codes_result = await phone_codes.json();
			const languages_result = await languages.json();
			const collections_result = await collections.json();
			const themes_result = await themes.json();

			phone_codes_result.data = phone_codes_result.data.filter((item) => item.callingCodes.find((item) => item !== "") !== undefined);
			phone_codes_result.data = phone_codes_result.data.filter((item) => item.callingCodes.find((item) => item !== "") !== undefined);
			phone_codes_result.data.map((item) => {
				item.callingCodes = item.callingCodes[0];
				delete item.currencies;
				delete item.languages;
				delete item.timezones;
			});
			// phone_codes_result.data = phone_codes_result.data.map((item) => item.callingCodes.find(item => item !== "") );
			// console.log(phone_codes_result)

			setPhoneCodes(phone_codes_result);
			setLanguages(languages_result);
			setCollections(collections_result);
			setThemes(themes_result);

			setDataLanguage(languages_result.data[0].name);
			setDataTheme(themes_result.data[0].name);
			if (phone_codes_result && languages_result && collections_result && themes_result) {
				setLoad(true);
			}
		} catch (e) {
			console.log(e);
		}
	};

	return (
		<>
			<Helmet>
				<title>Form</title>
			</Helmet>

			{load ? (
				<Grid id="referralForm" container direction="column" justifyContent="center" alignItems="center" paddingY={10} paddingX={5} backgroundColor={theme.palette.background.default}>
					<form onSubmit={handleSubmit}>
						<Grid item xs={12} marginBottom={5} textAlign={"center"}>
							<div style={{ width: "60%", margin: "auto", marginBottom: "20px" }}>
								<h5>Registra tú Tienda</h5>
								<h6>Información de Usuario</h6>
								<p>Completa tus datos básicos y crea una cuenta con correo y contraseña para entrar a tu administrador o encontrar tu tienda fácilmente.</p>
							</div>
							<Grid container direction="row" justifyContent="center" alignItems="center">
								<Grid item xs={12} sm={6} lg={4} paddingX={5} marginBottom={2}>
									<CssTextField required color="primary" name="first_name" label="Nombre" variant="filled" size="small" fullWidth />
								</Grid>
								<Grid item xs={12} sm={6} lg={4} paddingX={5} marginBottom={2}>
									<CssTextField required color="primary" name="last_name" label="Apellido" variant="filled" size="small" fullWidth />
								</Grid>
								<Grid item xs={12} sm={6} lg={4} paddingX={5} marginBottom={2}>
									<Autocomplete
										autoHighlight
										options={phoneCodes.data}
										isOptionEqualToValue={(option, value) => option.callingCodes === value.callingCodes}
										renderInput={(params) => <TextField {...params} required name="phone_code" label="" variant="filled" size="small" placeholder="+1" />}
										getOptionLabel={(option) => option.callingCodes || ""}
										renderOption={(props, option) => {
											props.key = props["data-option-index"];
											return (
												<Box component="li" sx={{ "& > img": { mr: 2, flexShrink: 0 } }} {...props}>
													<img loading="lazy" width="20" src={`https://flagcdn.com/w20/${option.alpha2Code.toLowerCase()}.png`} srcSet={`https://flagcdn.com/w40/${option.alpha2Code.toLowerCase()}.png 2x`} alt="" />({option.alpha2Code}) +{option.callingCodes}
												</Box>
											);
										}}
										style={{ display: "inline-flex", width: "20%" }}
									/>
									<CssTextField required color="primary" name="phone" label="Teléfono" variant="filled" size="small" style={{ width: "80%" }} />
								</Grid>
								<Grid item xs={12} sm={6} lg={4} paddingX={5} marginBottom={2}>
									<CssTextField required color="primary" name="email" label="Correo Electrónico" variant="filled" size="small" fullWidth />
								</Grid>
								<Grid item xs={12} sm={6} lg={4} paddingX={5} marginBottom={2}>
									<CssTextField required color="primary" name="password" type="password" label="Contraseña" variant="filled" size="small" fullWidth onChange={handlePassword} />
								</Grid>
								<Grid item xs={12} sm={6} lg={4} paddingX={5} marginBottom={2}>
									{passwordConfirmation === false ? (
										<CssTextField error helperText="Las contraseñas tienen que ser iguales" required color="primary" name="password_confirmation" type="password" label="Confirmación de Contraseña" variant="filled" size="small" fullWidth onChange={handlePasswordConfirmation} />
									) : (
										<CssTextField required color="primary" name="password_confirmation" type="password" label="Confirmación de Contraseña" variant="filled" size="small" fullWidth onChange={handlePasswordConfirmation} />
									)}
								</Grid>
								<Grid item xs={12} sm={6} lg={4} paddingX={5} marginBottom={2}>
									<FormControl fullWidth>
										<InputLabel id="demo-simple-select-label">Selecciona el idioma de tu Tienda</InputLabel>
										<Select labelId="demo-simple-select-label" required name="language" id="demo-simple-select" value={dataLanguage} label="Selecciona el idioma de tu Tienda" variant="filled" size="small" fullWidth onChange={(e) => setDataLanguage(e.target.value)}>
											{languages.data.map((item, key) => {
												return (
													<MenuItem key={key} value={item.name}>
														<img loading="lazy" width="20" src={`https://flagcdn.com/w20/${item.code.split("-")[1].toLowerCase()}.png`} srcSet={`https://flagcdn.com/w40/${item.code.split("-")[1].toLowerCase()}.png 2x`} alt="" /> {item.name}
													</MenuItem>
												);
											})}
										</Select>
									</FormControl>
								</Grid>
								<Grid item xs={12} sm={6} lg={4} paddingX={5} marginBottom={2}>
									<CssTextField color="primary" name="paypal_email" label="Correo Electrónico de Paypal" variant="filled" size="small" fullWidth />
								</Grid>
							</Grid>
						</Grid>
						<Grid item xs={12} marginBottom={5} textAlign={"center"}>
							<div style={{ width: "60%", margin: "auto", marginBottom: "20px" }}>
								<h6>Configuración de Tienda</h6>
								<p>Selecciona el nombre que quieres ponerle a tu tienda y los medios de comunicación que darás a tus clientes para que se pongan en contacto contigo, asesorar sus ventas o abrir nuevas tiendas.</p>
							</div>
							<Grid container direction="row" justifyContent="center" alignItems="center">
								<Grid item xs={12} sm={6} lg={4} paddingX={5} marginBottom={2}>
									<CssTextField
										required
										color="primary"
										name="store_name"
										label="Nombre"
										variant="filled"
										size="small"
										fullWidth
										onChange={handleStoreName}
										onBlur={verifyStore}
										InputProps={{
											endAdornment: (
												<InputAdornment position="end">
													<Button variant="contained" style={{ backgroundColor: storeNameValid === null ? "#000" : storeNameValid ? "#4caf50" : "#ab003c" }} onClick={verifyStore}>
														{storeNameValid === null ? "Verificar" : storeNameValid ? "Disponible" : "No disponible"}
													</Button>
												</InputAdornment>
											),
										}}
									/>
								</Grid>
								<Grid item xs={12} sm={6} lg={4} paddingX={5} marginBottom={2}>
									<CssTextField required color="primary" name="store_name_initials" type={"text"} label="Iniciales de la tienda" variant="filled" size="small" fullWidth inputProps={{ maxLength: 3 }} />
								</Grid>
								<Grid item xs={12} sm={6} lg={4} paddingX={5} marginBottom={2}>
									<CssTextField required color="primary" name="store_email" type={"email"} label="Correo Electrónico de Contacto para tus Clientes" variant="filled" size="small" fullWidth />
								</Grid>
								<Grid item xs={12} sm={6} lg={4} paddingX={5} marginBottom={2}>
									<Autocomplete
										autoHighlight
										options={phoneCodes.data}
										isOptionEqualToValue={(option, value) => option.callingCodes === value.callingCodes}
										renderInput={(params) => <TextField {...params} required label="" name="phone_store_code" variant="filled" size="small" placeholder="+1" />}
										getOptionLabel={(option) => option.callingCodes || ""}
										renderOption={(props, option) => {
											props.key = props["data-option-index"];
											return (
												<Box component="li" sx={{ "& > img": { mr: 2, flexShrink: 0 } }} {...props}>
													<img loading="lazy" width="20" src={`https://flagcdn.com/w20/${option.alpha2Code.toLowerCase()}.png`} srcSet={`https://flagcdn.com/w40/${option.alpha2Code.toLowerCase()}.png 2x`} alt="" />({option.alpha2Code}) +{option.callingCodes}
												</Box>
											);
										}}
										style={{ display: "inline-flex", width: "20%" }}
									/>
									<CssTextField required color="primary" name="store_phone" type={"tel"} label="Teléfono de Contacto para tus Clientes" variant="filled" size="small" fullWidth style={{ width: "80%" }} />
								</Grid>
								<Grid item xs={12} sm={6} lg={4} paddingX={5} marginBottom={2}>
									<FormGroup>
										<FormControlLabel control={<Checkbox name="whatsapp_check" checked={whatsappCheck} onChange={handleWhatsappCheck} />} label="Añadir enlace de WhatsApp" />
									</FormGroup>
								</Grid>
								<Grid item xs={12} sm={6} lg={4} paddingX={5} marginBottom={2}>
									<FormControl fullWidth>
										<InputLabel id="demo-simple-select-label">Tema de Tienda</InputLabel>
										<Select labelId="demo-simple-select-label" required name="theme_color" id="demo-simple-select" value={dataTheme} label="Selecciona el idioma de tu Tienda" variant="filled" size="small" onChange={(e) => setDataTheme(e.target.value)}>
											{themes.data.map((item, key) => {
												return (
													<MenuItem key={key} className="theme-colors" value={item.name}>
														<div
															className="color"
															style={{
																borderColor: "#000",
																backgroundColor: item.primary_color,
															}}></div>
														<div
															className="color"
															style={{
																borderColor: "#000",
																backgroundColor: item.secondary_color,
															}}></div>
														<div
															className="color"
															style={{
																borderColor: "#000",
																backgroundColor: item.background_color,
															}}></div>
														{item.title}
													</MenuItem>
												);
											})}
										</Select>
									</FormControl>
									{/* <CssTextField required select value={"Option 1"} color="primary" label="Tema de Tienda" variant="filled" size="small" fullWidth>
										<option key={1} value={"Option 1"}>
											Option 1
										</option>
									</CssTextField> */}
								</Grid>
							</Grid>
						</Grid>
						<Grid item xs={12} marginBottom={5} textAlign={"center"}>
							<div style={{ width: "60%", margin: "auto", marginBottom: "20px" }}>
								<h6>Datos de Compañía</h6>
								<p>En caso te registres como parte de una empresa, Escribe su nombre y su tax id, de lo contrario omite la pregunta seleccionando NO.</p>
							</div>
							<Grid container direction="row" justifyContent="center" alignItems="center">
								<FormControl>
									<FormLabel id="demo-radio-buttons-group-label">¿Tienes una compañía registrada?</FormLabel>
									<RadioGroup row required aria-labelledby="demo-radio-buttons-group-label" defaultValue="no" name="company" onChange={handleCompanyRadio} style={{ display: "inline-flex", justifyContent: "center" }}>
										<FormControlLabel value="yes" control={<Radio />} label="Yes" />
										<FormControlLabel value="no" control={<Radio />} label="No" />
										{companyRadio === "yes" ? (
											<>
												<CssTextField required color="primary" name="company_name" type={"text"} label="Nombre de Compañía" variant="filled" size="small" fullWidth style={{ marginBottom: 16 }} />
												<CssTextField required color="primary" name="tax_id" type={"text"} label="Tax Id / ENI" variant="filled" size="small" fullWidth />
											</>
										) : (
											""
										)}
									</RadioGroup>
								</FormControl>
							</Grid>
						</Grid>
						<Grid item xs={12} marginBottom={5} textAlign={"center"}>
							<div style={{ width: "60%", margin: "auto", marginBottom: "20px" }}>
								<h6>Información de Dirección</h6>
								<p>Completa los campos con los datos de tu domicilio o los de tu empresa según sea el caso.</p>
							</div>
							<Grid container direction="row" justifyContent="center" alignItems="center">
								<Grid item xs={12} sm={6} lg={4} paddingX={5} marginBottom={2}>
									<CssTextField required color="primary" name="country" label="País" variant="filled" size="small" fullWidth />
								</Grid>
								<Grid item xs={12} sm={6} lg={4} paddingX={5} marginBottom={2}>
									<CssTextField required color="primary" name="province" label="Provincia" variant="filled" size="small" fullWidth />
								</Grid>
								<Grid item xs={12} sm={6} lg={4} paddingX={5} marginBottom={2}>
									<CssTextField required color="primary" name="city" label="Ciudad" variant="filled" size="small" fullWidth />
								</Grid>
								<Grid item xs={12} sm={6} lg={4} paddingX={5} marginBottom={2}>
									<CssTextField required color="primary" name="zip_code" label="Código ZIP" variant="filled" size="small" fullWidth />
								</Grid>
								<Grid item xs={12} sm={6} lg={4} paddingX={5} marginBottom={2}>
									<CssTextField required color="primary" name="address_line1" label="Dirección 1" variant="filled" size="small" fullWidth />
								</Grid>
								<Grid item xs={12} sm={6} lg={4} paddingX={5} marginBottom={2}>
									<CssTextField color="primary" name="address_line2" label="Dirección 2" variant="filled" size="small" fullWidth />
								</Grid>
								<Grid item xs={12} paddingX={5} marginTop={2} marginBottom={2}>
									<FormControl>
										<FormLabel id="demo-radio-buttons-group-label">¿Cómo supiste de nosotros?</FormLabel>
										<RadioGroup row required aria-labelledby="demo-radio-buttons-group-label" name="social_network_search" onChange={handleSocialRadio} style={{ display: "inline-flex", justifyContent: "center" }}>
											<FormControlLabel value="Instagram" control={<Radio />} label="Instagram" />
											<FormControlLabel value="Facebook" control={<Radio />} label="Facebook" />
											<FormControlLabel value="Tiktok" control={<Radio />} label="Tiktok" />
											<FormControlLabel value="Pinterest" control={<Radio />} label="Pinterest" />
											<FormControlLabel value="Friends" control={<Radio />} label="Conocidos o Amigos" />
											<FormControlLabel value="Others" control={<Radio />} label="Otros" />
											{socialRadio === "Others" ? (
												<>
													<CssTextField required color="primary" name="social_media" type={"text"} label="Red social" variant="filled" size="small" fullWidth style={{ marginBottom: 16 }} />
												</>
											) : (
												""
											)}
										</RadioGroup>
									</FormControl>
								</Grid>
								<Grid item xs={12} sm={6} paddingX={5} marginBottom={2}>
									<Checkbox required />
									<Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
										<Box sx={styleModal}>
											<div class="modal-referral">
												<div>
													<h5>Terminos y Condiciones</h5>
												</div>
												<div>
													<div>
														<ul class="politicas-head">
															<h6>GENERALES</h6>
															<p>Las ganancias por las ventas se verán reflejadas de la siguiente forma:</p>
															<p>Sobre todas las ventas de tus tiendas, tendrás hasta el 40% de ganancia y adicional, tendrás un 5% sobre las ventas de las tiendas de tus referidos directos.*</p>
															<p>Recibirás las comisiones de las ventas de tus referidas mientras tu tienda se encuentre activa o con órdenes durante ese periodo.</p>
															<p>
																Ventadirekta solicita los datos de tu TDC (Tarjeta de crédito)solo en los casos de activar el sistema de muestras o devoluciones dentro de tu tienda.&nbsp;
																<br />
																En el primer caso, al estar inscrito y no cumplir con las políticas de tiempos de retorno o calidad de los productos, se hace un cobro del 50% del costo de las prendas enviadas. Para el segundo escenario, se descuenta el valor de la
																ganancia consignada correspondiente a las devoluciones efectuadas de ventas el mes anterior.
															</p>
															<p>
																A tu cuenta de Paypal son consignados los primeros 10 días de cada mes las ganancias de las ventas realizadas el mes pasado. En caso de recibir una o varias devoluciones, debemos descontar de tu extracto actual el valor de las prendas
																regresadas y, verás en tu extracto el campo “last month balance“ con este valor.&nbsp;
																<br />
																Tus ganancias del mes en curso puede cubrirlo; sin embargo, en caso de no ser así, tiene dos meses para hacerlo. Al terminar este periodo y el valor aún está pendiente, este rubro será cobrado a tu tarjeta.
															</p>
															<p>*Aplica solo para los usuarios que se registren a través del link de referidos dentro de la tienda de la embajadora.</p>
															<h6>Formulario W9:</h6>
															<p>
																Con la información proporcionada en este formulario, la empresa elabora el formulario 1099-MISC, el cual notifica el monto total pagado al proveedor el año anterior. En caso de ser un monto superior o igual a $600 dólares, se envía
																copia de este formulario tanto al proveedor como a la IRS.
															</p>
															<h6>Programa de muestras:</h6>
															<p>
																Prestamos a nuestras embajadoras 3 productos de muestra para que conozcan y presenten a sus clientes por un periodo de una semana. Al culminar este tiempo, las prendas deben ser regresadas al almacén para recibir otras 3 referencias
																diferentes.&nbsp;
																<br />
																Las prendas serán elegidas por la embajadora. Los envíos y retornos no tienen ningún costo.
															</p>
															<p>Si eres una de las embajadoras que desea disfrutar de nuestro programa de fajas, debes cumplir con lo siguiente:</p>
															<p>
																- Tendrás una semana para generar contenido en las redes sociales (Instagram, Facebook, Tiktok, etc)&nbsp;
																<br />- Generar contenido de vídeo y fotos mostrando la prenda, explicando sus beneficios e ilustrando el antes y después de su uso, cómo utilizarla en el día a día, y todas las ideas que tengas.&nbsp;
																<br />- Mencionar tu tienda e incluir el link directo a ella desde todas las redes
															</p>
															<p>Para guiarte en el proceso de creación de contenido sobre los productos cuentan con VDK Academy, la academia de Ventadirekta con tutoriales y capacitaciones.</p>
														</ul>
													</div>
												</div>
											</div>
										</Box>
									</Modal>
									<Button onClick={handleOpen}>Acepto Términos y Condiciones</Button>
									{/* <a href="#">Acepto Términos y Condiciones</a> */}
								</Grid>
							</Grid>
						</Grid>
						<Grid item xs={12} marginBottom={5} textAlign={"center"}>
							<Button variant="contained" type="submit">
								Enviar
							</Button>
						</Grid>
					</form>
				</Grid>
			) : (
				<div style={{ width: "100%", height: "100vh", backgroundColor: theme.palette.background.default, display: "inline-flex", justifyContent: "center", alignItems: "center" }}>
					<CircularProgress style={{ color: "#000" }} />
				</div>
			)}
		</>
	);
};
