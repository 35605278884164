import React, { useState, useEffect } from "react";
import CustomText from "../../CustomText/CustomText";
import { useTheme } from "@mui/material/styles";
import {} from "./style.css";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { styled } from "@mui/material/styles";

const CustomSelect = styled(Select)(({ theme }) => ({
	".MuiSelect-select": {
		borderRadius: 4,
		position: "relative",
		backgroundColor: theme.palette.background.default,
		color: theme.palette.text.primary,
		border: "none",
		borderBottom: "none",
		borderBottomLeftRadius: 0,
		borderBottomRightRadius: 0,
		fontFamily: "ClashDisplay-Regular",
		fontSize: 16,
		"&:focus": {
			borderRadius: 0,
			borderColor: "none",
			boxShadow: "none",
			border: "none",
		},
		"&:before": {
			borderRadius: 0,
			borderColor: "none",
			boxShadow: "none",
			border: "none",
		},
		"&:after": {
			borderRadius: 0,
			borderColor: "none",
			boxShadow: "none",
			border: "none",
		},
	},
	"& .MuiSelect-standard": {
		border: "none",
	},
}));

export const Sizes = (props) => {
	const theme = useTheme();
	const [size, setSize] = useState(props.productVariant);
	const [sizes, setSizes] = useState();
	const [load, setLoad] = useState(false);
	const [sizeSelected, setSizeSelected] = useState("");

	let selected_variant = props.variants.filter((item) => item.sku === props.productVariant);

	function val(e) {
		let result = JSON.parse(e.target.value);
		setSizeSelected(result.name);
		setSize(result.sku);
		props.setProductVariant(result.sku);
		props.setMaxQuantity(props.variants.filter((item) => item.sku === result.sku)[0].inventory_quantity);
	}
	useEffect(() => {
		setSizeSelected(selected_variant[0].option1);
		setSizes(props.variants.filter((item) => item.option2 === selected_variant[0].option2));
		setLoad(true);
	}, [props.productVariant]);

	if (load) {
		return (
			<div className="size-select" style={props.style}>
				{/* <CustomText title={sizeSelected} display={"flex"} alignItems={"center"} height={"100%"} fontSize={window.innerWidth <= 700 ? 24 : 18} color={theme.palette.text.primary} /> */}

				{/* <div
					className="divider"
					style={{
						backgroundColor: theme.palette.text.primary,
						margin: "0 2px",
					}}
				/> */}
				<CustomSelect id={"select_id"} onChange={val} variant="standard" value={JSON.stringify({ sku: props.variants.filter((item) => item.sku === props.productVariant)[0].sku, name: props.variants.filter((item) => item.sku === props.productVariant)[0].option1 })}>
					{props.variants
						.filter((item) => item.option2 === selected_variant[0].option2)
						.filter((item) => item.inventory_quantity > 0)
						.map((item, index) => (
							<MenuItem
								key={index}
								style={{
									color: theme.palette.text.primary,
								}}
								value={JSON.stringify({ sku: item.sku, name: item.option1 })}>
								{item.option3 !== "no-option" ? `${item.option1} / ${item.option3}` : item.option1}
							</MenuItem>
						))}
				</CustomSelect>
			</div>
		);
	} else {
		return (
			<div className="size-select">
				<CustomText title={"Cargando..."} fontSize={window.innerWidth <= 700 ? 13 : 18} color={theme.palette.text.primary} />
			</div>
		);
	}
};
