import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Grid } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { useTheme } from "@mui/material/styles";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import { colors } from "../../../../Utils/Colors";
import CustomText from "../../../Common/CustomText/CustomText";
import Spacer from "../../../Common/Spacer/Spacer";
import NewArrivals from "../../../Common/NewArrivals/NewArrivals";

import Button from "@mui/material/Button";
import Box from "@mui/material/Box";

import imageBeauty from "../../../../Assets/Collections/Banners/Mobile/PRODUCTOS-NATURALES-DE-BELLEZA.jpg";
import imageDeportiva from "../../../../Assets/Collections/Banners/Mobile/ROPA-DEPORTIVA.jpg";
import imageFajas from "../../../../Assets/Collections/Banners/Mobile/FAJAS.jpg";
import imageJeans from "../../../../Assets/Collections/Banners/Mobile/JEANS.jpg";
import imageInterior from "../../../../Assets/Collections/Banners/Mobile/ROPA-INTERIOR-DE-COMPRESIÓN.jpg";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

import { Card, CardFooter, CardImage, CardImageBG } from "../../../Common/Card/Card";

import { Filters } from "../../../Common/index";
import { StoreContext } from "../../../../Context/storeContext";


const CollectionMobile = (props) => {
	const theme = useTheme();
	const store_data = useContext(StoreContext);
	let urlParams = useParams();
	let collection_data = store_data.collections_all.filter((item) => item.handle === urlParams.collection);
	// let products_new = store_data.collections_all.filter((item) => item.handle === urlParams.collection).map((item) => item.sort_products)[0];
	// let products = store_data.collections_all.filter((item) => item.handle === urlParams.collection).map((item) => item.sort_products)[0];

	const [products, setProducts] = useState(store_data.collections_all.filter((item) => item.handle === urlParams.collection).map((item) => item.sort_products)[0]);
	const [setFilters, setSetFilters] = useState(false);
	const [filterProducts, setFilterProducts] = useState([]);
	// let split_collection = chunk(setFilters ? filterProducts : products, 10);
	const [split_collection, setsplit_collection] = useState(chunk(setFilters ? filterProducts : products, 10));

	const [divHeight, setDivHeight] = useState(document.getElementById("productContainer") ? document.getElementById("productContainer").clientHeight : window.innerHeight);
	const [productSrcollState, setProductSrcollState] = useState(0);
	const [productSrcoll, setProductSrcoll] = useState(split_collection[0]);
	const [scrollLoader, setScrollLoader] = useState(false);
	const [load, setLoad] = useState(false);

	useEffect(() => {

		setLoad(false);
		setSetFilters(false)
		setProducts(store_data.collections_all.filter((item) => item.handle === urlParams.collection).map((item) => item.sort_products)[0]);
	}, [urlParams.collection]);

	useEffect(() => {
		
		setsplit_collection(chunk(setFilters ? filterProducts : products, 10));
		setDivHeight(document.getElementById("productContainer") ? document.getElementById("productContainer").clientHeight : window.innerHeight);
		setProductSrcollState(0);
		setProductSrcoll(chunk(setFilters ? filterProducts : products, 10)[0]);
		setTimeout(() => {
			setLoad(true);
		}, 2500);
	}, [products]);

	useEffect(() => {

		setsplit_collection(chunk(setFilters ? filterProducts : products, 10));
		setDivHeight(document.getElementById("productContainer") ? document.getElementById("productContainer").clientHeight : window.innerHeight);
		setProductSrcollState(0);
		setTimeout(() => {
			setProductSrcoll(chunk(setFilters ? filterProducts : products, 10)[0]);
		}, 1000);
	}, [filterProducts]);
	
	useEffect(() => {
		const onScroll = function () {
			// var clientHeight = document.getElementById("productContainer").clientHeight;
			if (window.innerHeight + window.scrollY >= divHeight) {
				if (productSrcollState + 1 <= split_collection.length - 1) {
					setScrollLoader(true);

					setTimeout(() => {
						setProductSrcoll(productSrcoll.concat(split_collection[productSrcollState + 1]));
						setProductSrcollState(productSrcollState + 1);
						setScrollLoader(false);
					}, 2000);
					setDivHeight(document.getElementById("productContainer").clientHeight);
				}
			}
		};
		window.addEventListener("scroll", onScroll);
		return () => window.removeEventListener("scroll", onScroll);
	}, [divHeight, productSrcoll, productSrcollState]);

	function chunk(arr, len) {
		let chunks = [],
			i = 0,
			n = arr.length;

		if (arr.length > 0) {
			while (i < n) {
				chunks.push(arr.slice(i, (i += len)));
			}
		} else {
			chunks.push([]);
		}

		return chunks;
	}

	let collection_images = {
		"beauty-health": imageBeauty,
		"ropa-deportiva": imageDeportiva,
		"fajas-colombianas": imageFajas,
		"jeans-colombianos": imageJeans,
		"ropa-interior": imageInterior,
		"shorts": imageInterior,
	};

	const [isDrawerOpen, setIsDrawerOpen] = useState(false);
	const [openModal, setOpenModal] = useState(false);

	const handleOpenModalFilters = () => {
		setIsDrawerOpen(true)
	};
	const handleCloseModalFilters = () => {
		setIsDrawerOpen(false);
	};

	function handleScrollLoad(){
		if (productSrcollState + 1 <= split_collection.length - 1) {
			setScrollLoader(true);

			setTimeout(() => {
				setProductSrcoll(productSrcoll.concat(split_collection[productSrcollState + 1]));
				setProductSrcollState(productSrcollState + 1);
				setScrollLoader(false);
			}, 2000);
			setDivHeight(document.getElementById("productContainer").clientHeight);
		}
	}
	return (
		<div style={{ backgroundColor: theme.palette.background.default }} id="productContainer">

			<button id="buttonFilterModal" onClick={handleOpenModalFilters} style={{display: "none"}}></button>

			<div style={{ width: "100%", height: "55vh", backgroundPosition: "center", backgroundImage: `url(https://admin.ventadirekta.com/images/collections/collection-${urlParams.collection}.jpg)`, backgroundSize: "cover" }}>
				<div
					style={{
						position: "absolute",
						width: "auto",
						padding: 40,
						display: "flex",
						flexDirection: "column",
						justifyContent: "flex-end",
						height: 400,
					}}>
					<div>
						<CustomText title={collection_data[0].name} fontFamily={"ClashDisplay-SemiBold"} fontSize={33} color={colors.white} />
					</div>
				</div>
			</div>

			<div
				style={{
					width: window.innerWidth <= 1000 ? "100%" : "auto",
					paddingRight: window.innerWidth <= 1000 ? "" : 100,
					paddingLeft: window.innerWidth <= 1000 ? "" : 100,
					backgroundColor: theme.palette.background.default,
				}}>
				{/* <div style={{ width: "100%", }}> */}
				<Spacer height={window.innerWidth <= 1000 ? 80 : ""} />
				{load && !setFilters ? <NewArrivals light={true} /> : ""}
			</div>

			{load ? (
				<Grid container justifyContent={"center"} flexDirection={"row"}>
					<Grid item xs={12} padding={"0 14px 5px"}>
						<p style={{fontFamily: "ClashDisplay-Regular", fontSize: 12}}>{store_data.translations["translate-all-pages-body-32"]}: <span style={{fontFamily: "ClashDisplay-SemiBold", fontSize: 12}}>{setFilters ? filterProducts.length : products.length}</span></p>
					</Grid>
					<FormatProducts />
					{productSrcollState + 1 <= split_collection.length - 1 && !scrollLoader ? (

						<Button variant="contained" color="secondary" style={{margin: "20px auto 0"}} onClick={handleScrollLoad}>{store_data.translations["translate-all-pages-body-25"]}</Button>
					) : ''}
				</Grid>
			) : (
				<div
					style={{
						display: "inline-flex",
						justifyContent: "center",
						alignItems: "center",
						width: "100%",
						height: "160px",
					}}>
					<CircularProgress style={{ color: theme.palette.primary.main }} />
				</div>
			)}

			<SwipeableDrawer anchor="left" open={isDrawerOpen} sx={{}} onClose={() => setIsDrawerOpen(false)} onOpen={() => setIsDrawerOpen(true)}>
				<Box p={2} role="presentation" textAlign="center" backgroundColor={theme.palette.background.default} height={"100%"} padding={'20px 0'} width={350}>
					<Filters products={store_data.collections_all.filter((item) => item.handle === urlParams.collection).map((item) => item.sort_products)[0]} filterProducts={filterProducts} setFilterProducts={setFilterProducts} setSetFilters={setSetFilters} setLoad={setLoad} />
				</Box>
			</SwipeableDrawer>

		</div>
	);

	function FormatProducts() {
		let products_arr = [];

		if (productSrcoll && productSrcoll.length > 0) {

			for (const item of productSrcoll) {
				products_arr.push(
					<Grid item xs={12} sm={6} key={item._id} padding={"0 14px"} marginBottom={"40px"}>
						<CardImageBG image={item.image} height={"auto"} id={item.id} title={item.title} handle={item.handle} price={item.min_price.format} light={props.light} product={item} />
						<Spacer height={10} />
						<CardFooter product={item} index={item._id} id={item.id} sku={item.skus[0]} price={item.min_price.format} light={props.light} />
					</Grid>
				);
			}
		}else{

			products_arr.push(
				<Grid item xs={12} padding={"0 14px"}>
					<h5 style={{fontFamily: "ClashDisplay-Regular", fontSize: 15, textAlign: "center", margin: "70px 0"}}>{store_data.translations["translate-all-pages-body-26"]}</h5>
				</Grid>
			);
		}

		return products_arr;
	}
};

export default CollectionMobile;
