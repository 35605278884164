import React, { useState, useContext } from "react";
import CustomText from "../../CustomText/CustomText";
import Spacer from "../../Spacer/Spacer";
import {} from "./style.css";

import { StoreContext } from "../../../../Context/storeContext";

export const Count = (props) => {
	const store_data = useContext(StoreContext);

	const [counter, setCounter] = useState(props.quantity ? props.quantity : 1);
	const addOne = (counter, setCounter) => {
		setCounter(counter + 1);
	};
	const minusOne = (counter, setCounter) => {
		if (counter > 1) setCounter(counter - 1);
	};


	if(props.minusOne){

		return(
			<div className="countDiv">
				<div className="minus">
					<CustomText
						title="-"
						fontSize={20}
						onClick={() => props.minusOne(props.counter, props.setCounter)}
					/>
				</div>
	
				<Spacer width={20} />
				<CustomText title={props.counter} fontSize={20} />
	
				<Spacer width={20} />
				<div className="add"
					onClick={() => props.addOne(props.counter, props.setCounter)}>
					<CustomText title="+" fontSize={20} />
				</div>
			</div>
		)
	}else if(props.productSku){
		let cartItems = JSON.parse(localStorage.getItem("cart"));
		let product = store_data.filter_products.find(
			(item) => item.skus.includes(props.productSku)
		);
		if (!product) {
			localStorage.setItem("cart", JSON.stringify(cartItems.filter((item) => item.variant.sku !== props.productSku)));
			dispatchEvent(new Event("storage"));
			cartItems = JSON.parse(localStorage.getItem("cart"));
		}
		let variant = product.variants.find((item) => item.sku === props.productSku);
		let max_quantity = variant.inventory_quantity;
		
		function updateCart(quantity, action) {
			
			let item = cartItems.find(
				(item) => item.variant.sku ===  props.productSku
			);
			item.quantity = quantity;
			item.total = parseFloat(item.price) * parseInt(item.quantity);
			localStorage.setItem("cart", JSON.stringify(cartItems));

			if (action === "add") {

				// props.setCartSubTotal(props.cartSubTotal + item.price);
				props.setCartSubTotal((currentSubTotal) => currentSubTotal + item.price);
			}else if (action === "minus"){

				props.setCartSubTotal((currentSubTotal) => currentSubTotal - item.price);
			}

			dispatchEvent(new Event("storage"));
		}
		
		return (
			<div className="countDiv">
				<div className="minus">
					<CustomText
						title="-"
						fontSize={20}
						onClick={() => {setCounter(counter - 1 >= 1 ? counter - 1 : 1); updateCart(counter - 1 >= 1 ? counter - 1 : 1, counter - 1 >= 1 ? "minus" : null); }}
					/>
				</div>
	
				<Spacer width={20} />
				<CustomText title={counter} fontSize={20} />
	
				<Spacer width={20} />
				<div className="add"
					// onClick={() => addOne(counter, setCounter)}>
					onClick={() => {setCounter(counter + 1 <= max_quantity ? counter + 1 : counter); updateCart(counter + 1 <= max_quantity ? counter + 1 : counter, counter + 1 <= max_quantity ? "add" : null);}}>
					<CustomText title="+" fontSize={20} />
				</div>
			</div>
		);
	}else{

		return (
			<div className="countDiv">
				<div className="minus">
					<CustomText
						title="-"
						fontSize={20}
						onClick={() => minusOne(counter, setCounter)}
					/>
				</div>
	
				<Spacer width={20} />
				<CustomText title={counter} fontSize={20} />
	
				<Spacer width={20} />
				<div className="add"
					onClick={() => addOne(counter, setCounter)}>
					<CustomText title="+" fontSize={20} />
				</div>
			</div>
		);
	}
}
