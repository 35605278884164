export const icons = {
    circleRightArrow: require('./circleRightArrow.png'),
    downArrow: require('./downArrow.png'),
    downArrowLine: require('./downArrowLine.png'),
    plus: require('./plus.png'),
    search: require('./search.png'),
    shoppingCart: require('./shoppingCart.png'),
    sideBar: require('./sideBar.png'),
    rightCircleArrow: require('./rightCircleArrow.png'),
    rightArrow: require('./rightArrow.png'),
    dropdown: require('./dropdown.png'),
    share: require('./share.png'),
    layout: require('./Layout.png'),
    home: require('./Home.png'),
    headset: require('./Headset.png'),
    drawer: require('./drawer.png'),
    upArrowCircle: require('./upArrowCircle.png'),
    whatsApp: require('./whatsApp.png'),
    cross: require('./cross.png'),
    cartWhite: require('./cartWhite.png'),
    user: require('./User.png'),
    leftArrow: require('./leftArrow.png'),
    line: require('./line.png'),
    
}