import CustomText from "../../Components/Common/CustomText/CustomText";
import { Helmet } from 'react-helmet-async';
import {} from "./style.css"

export const NotFound = () => {
	return (
		<>
			<Helmet>
				<title>Not Found</title>
				<meta name='description' content='' />
			</Helmet>
			<div className="not-found-page">
				<CustomText
					title="Page not Found"
					fontFamily={"ClashDisplay-SemiBold"}
					fontSize={20}
				/>
				<CustomText
					title="ERROR 404"
					fontFamily={"ClashDisplay-SemiBold"}
					fontSize={45}
				/>
			</div>
		</>
	);
};

// export default NotFound
