import React from "react";
import "../../../Assets/Style/font.css";

function CustomText({ ...props }) {
	return (
		<div
			onClick={props.onClick}
			disabled={!props.onClick}
			className={props.className ? props.className : ''}
			title={props.titleTL ? props.titleTL : ''}
			style={{
				color: props.color || "black",
				fontSize: props.fontSize || 20,
				margin: props.margin,
				marginTop: props.margin,
				marginBottom: props.wrap ? 0 : props.marginBottom,
				marginLeft: props.marginLeft,
				marginRight: props.marginRight,
				padding: props.padding,
				paddingLeft: props.paddingLeft,
				paddingBottom: props.wrap ? 0 : props.paddingBottom,
				alignSelf: props.alignSelf || "flex-start",
				alignItems: props.alignItems,
				fontWeight: props.fontWeight,
				fontStyle: props.fontStyle,
				textAlign: props.textAlign,
				fontFamily: props.fontFamily || "ClashDisplay-Regular",
				lineHeight: props.lineHeight,
				float: props.float,
				listStyle: props.listStyle,
				position: props.position,
				left: props.left,
				textDecoration: props.textDecoration,
				textTransform: props.textTransform,
				maxLines: props.maxLines,
				cursor: props.cursor ? props.cursor : "inherit",
				overflow: props.wrap ? "hidden" : '',
				textOverflow: props.wrap ? "ellipsis" : '',
				// whiteSpace: props.wrap ? "nowrap" : '',
				display: props.wrap ? "-webkit-box" : props.display,
				WebkitLineClamp: props.wrap ? props.lines ? props.lines : 2 : '',
				WebkitBoxOrient: props.wrap ? "vertical" : ''
			}}>
			{props.title}
			{props.value}
		</div>
	);
}

export default CustomText;
