import { useState, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { colors } from "../../../../Utils/Colors";
import Tooltip from "@mui/material/Tooltip";
import productColors from "../../../../Assets/Products/colors.json";
import {} from "./style.css";
import "swiper/css";
import "swiper/css/virtual";
import { useTheme } from "@mui/material/styles";

export const Colors = (props) => {
	const theme = useTheme();
	let variants = props.variants.filter((item) => item.inventory_quantity > 0);
	let selected_size = variants.filter(
		(item) => item.sku === props.productVariant
	);
	
	const [disponibleColors, setDisponibleColors] = useState(
		variants.filter(item => item.option1 === selected_size[0].option1).map(item => item.option2)
	);
	
	const [color, setColor] = useState(props.productVariant);
	const [colorSelected, setColorSelected] = useState(selected_size[0].option2);

	let filtered_colors = [...new Set(variants.map((item) => item.option2))];

	function val(e) {
		let result = e;
		setColorSelected(result);
		
		let selected_variant = variants.filter(
			(item) =>
				item.option2 === result &&
				item.option1 === selected_size[0].option1
		);

		if (props.images && props.images.find(item => item.id === selected_variant[0].image_id).src) {
			
			props.setMainImage(props.images.find(item => item.id === selected_variant[0].image_id).src)
		}
		props.setProductVariant(selected_variant[0].sku);
		props.setMaxQuantity(selected_variant[0].inventory_quantity);
	}
	useEffect(() => {

		setColorSelected(filtered_colors[0]);
		if (props.images) {
			
			props.setMainImage(props.images.find(item => item.id === selected_size[0].image_id).src)
		}
	}, []);
	useEffect(() => {

		setDisponibleColors(variants.filter(item => item.option1 === selected_size[0].option1).map(item => item.option2))
		setColorSelected(selected_size[0].option2);
	}, [props.productVariant]);

	return (
			<Swiper className="colors" spaceBetween={0} slidesPerView={3}>
				{filtered_colors.map((item, index) =>
					colorSelected === item ? (
						<SwiperSlide
							key={item}
							className="color-select selected"
							style={{background: theme.palette.background.default}}>
							<div
								// className="color-select selected"
								style={{ borderColor: colors.black }}>
								<Tooltip title={item === "no-option" ? '' : item} variant="soft">
									<div
										className="color"
										style={{
											borderColor: colors.black,
											backgroundColor:
												productColors[item],
										}}></div>
								</Tooltip>
							</div>
						</SwiperSlide>
					) : (
						disponibleColors.includes(item) ? (

							<SwiperSlide
								key={item}
								className="color-select"
								onClick={() => {
									// setColorSelected(item);
									val(item);
									// setColor(item);
									// props.setProductVariant(item);
								}}>
								<Tooltip title={item} variant="soft">
									<div
										className="color"
										style={{
											borderColor: colors.black,
											backgroundColor: productColors[item],
										}}></div>
								</Tooltip>
							</SwiperSlide>
						) : (
							<SwiperSlide
							key={item}
							className="color-select disabled">
							<Tooltip title={"No disponible"} variant="soft">
								<div
									className="color"
									style={{
										borderColor: colors.black,
										backgroundColor: productColors[item],
									}}></div>
							</Tooltip>
						</SwiperSlide>
						)
					)
				)}
			</Swiper>
	);
};
