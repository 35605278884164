import { useState, useContext, useEffect } from "react";
import { Outlet } from "react-router-dom";
import Navbar from "../../Components/Common/Navbar/Navbar";
import { Helmet } from "react-helmet-async";
import Footer from "../../Components/Common/Footer/Footer.jsx";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import BottomBarNavigation from "../../Components/Common/BottomNavigation/BottomNavigation.js";
import { useTheme } from "@mui/material/styles";
import { MuiDrawer } from "../../Components/Common/Sidebar/Sidebar";
import {} from "./style.css";

import { StoreContext } from "../../Context/storeContext.jsx";

const validProductionAdminUrl = function (url = "") {
	if (process.env.REACT_APP_NODE_ENV === "TEST") {
		return `http://localhost:3030${url}`;
	} else {
		return `https://admin.ventadirekta.com${url}`;
	}
};

export const Index = () => {
	useEffect(() => {
		//  scroll to top on page load
		window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
	}, []);

	const [on, setOn] = useState(false);
	const [light, setLight] = useState(true);

	const store_data = useContext(StoreContext);
	const theme = useTheme();
	const [isDrawerOpen, setIsDrawerOpen] = useState(false);

	const [isFiltersOpen, setIsFiltersOpen] = useState(false);

	return (
		<>
			<Helmet>
				<link rel="manifest" href={validProductionAdminUrl(store_data.manifest)} />
			</Helmet>

			<header className="general">
				<Navbar on={on} setOn={setOn} light={light} setLight={setLight} isDrawerOpen={isDrawerOpen} setIsDrawerOpen={setIsDrawerOpen} drawer={true} />
			</header>

			<MuiDrawer isDrawerOpen={isDrawerOpen} setIsDrawerOpen={setIsDrawerOpen} />

			<main className="general" style={{ backgroundColor: theme.palette.background.default }}>
				<Outlet />
			</main>

			<Footer className="general" />
			{window.innerWidth <= 1024 ? <BottomBarNavigation isDrawerOpen={isDrawerOpen} setIsDrawerOpen={setIsDrawerOpen} /> : ""}

			<ToastContainer />
		</>
	);
};
