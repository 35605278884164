import React, { Suspense, useEffect } from "react";
import { Route, Routes, Navigate, useNavigate, useSearchParams  } from "react-router-dom";
import LinearProgress from "@mui/material/LinearProgress";

import { Index as LayoutGeneral } from "../Layouts/General/index";
import { Index as LayoutHome } from "../Layouts/Home/index";

import { Collections } from "../Screens/Collections/Collections";
import { Brand } from "../Screens/Brand/Brand";
import { Search } from "../Screens/Search/index";
import { NewArrivals } from "../Screens/NewArrivals/index";
import { Download } from "../Screens/Download/index";
import { Referral } from "../Screens/Home/Referral/Referral";
import { NotFound } from "../Screens/Errors/404";
const Home = React.lazy(() => import("../Screens/Home/Home"));
const Cart = React.lazy(() => import("../Screens/Cart/Cart"));
const Product = React.lazy(() => import("../Screens/Product/Product"));
// const Referral = React.lazy(() => import("../Screens/Home/Referral"));

const validProductionAdminUrl = function (url = '') {
	if (process.env.REACT_APP_NODE_ENV === "TEST") {
		return `http://localhost:3030${ url }`;
	} else {
		return `https://admin.ventadirekta.com${ url }`;
	}
};

function Redirect({url}) {

	window.location.replace(url)
	return (<section>Redirecting...</section>);
  }
  

export default function StoresRouter() {
	let [searchParams, setSearchParams] = useSearchParams();
	const navigate = useNavigate();

	useEffect(() => {

		if (searchParams.get("lang")) {
			navigate('/');
		}
	}, [])
	

	return (
		<Suspense
			fallback={
				<div className="landing-pre-loader-div">
					<div className="landing-pre-loader">
						<img src="https://ventadirekta.com/assets/images/logo/logo-white.webp" alt="" />
						<LinearProgress style={{color: "white", background: "black", width: "100%"}} />
					</div>
				</div>
			}>
			<Routes>
				<Route element={<LayoutHome />}>
					<Route path="/" element={<Home />} title="Home" />
					<Route path="/referrals-register" element={<Referral />} title="Referral" />
				</Route>
				<Route element={<LayoutGeneral />}>
					<Route path="/cart" element={<Cart />} title="Cart" />
					<Route
						path="/collection/:collection"
						name="Collection"
						element={<Collections />}
						title="Collection"
					/>
					<Route path="/products/:handle" element={<Product />} title="Product"/>
					<Route path="/brands/:brand" element={<Brand />} title="Brands"/>
					<Route path="/search/:data" element={<Search />} title="Search"/>
					<Route path="/new-arrivals" element={<NewArrivals />} title="New Arrivals"/>
					<Route path="/download" element={<Download />} title="Download"/>
					<Route path="*" element={<NotFound />} />
				</Route>
				<Route path="/admin" element={<Redirect url={validProductionAdminUrl()} />}/>
				<Route path="/admin/*" element={<Redirect url={validProductionAdminUrl()} />}/>
			</Routes>
		</Suspense>
	);
}
