import { createTheme } from "@mui/material/styles";

export let colorPalette = [
	{
		palette: {
			mode: "light",
			primary: {
				main: "#1B1B1B",
			},
			secondary: {
				main: "#686868",
			},
			text: {
				primary: "#1B1B1B",
				secondary: "#fff",
			},
			background: {
				default: "#FFFFFF",
				dark: "#FDE3D0",
			},
		},
	},
	{
		palette: {
			mode: "light",
			primary: {
				main: "#884384",
			},
			secondary: {
				main: "#FF6B01",
			},
			text: {
				primary: "#1B1B1B",
				secondary: "#fff",
			},
			background: {
				default: "#FDE3D0",
				dark: "#FDE3D0",
			},
		},
	},
	{
		palette: {
			mode: "light",
			primary: {
				main: "#CA5DFF",
			},
			secondary: {
				main: "#1DC3B1",
			},
			text: {
				primary: "#1B1B1B",
				secondary: "#fff",
			},
			background: {
				default: "#F2FFFF",
				dark: "#FDE3D0",
			},
		},
	},
	{
		palette: {
			mode: "light",
			primary: {
				main: "#E7486F",
			},
			secondary: {
				main: "#39A1BD",
			},
			text: {
				primary: "#1B1B1B",
				secondary: "#fff",
			},
			background: {
				default: "#F7EFED",
				dark: "#FDE3D0",
			},
		},
	},
	{
		palette: {
			mode: "light",
			primary: {
				main: "#D84EA9",
			},
			secondary: {
				main: "#EFB733",
			},
			text: {
				primary: "#1B1B1B",
				secondary: "#fff",
			},
			background: {
				default: "#FFF9FE",
				dark: "#FDE3D0",
			},
		},
	},
	{
		palette: {
			mode: "light",
			primary: {
				main: "#C16565",
			},
			secondary: {
				main: "#542E2E",
			},
			text: {
				primary: "#1B1B1B",
				secondary: "#fff",
			},
			background: {
				default: "#F7EFED",
				dark: "#FDE3D0",
			},
		},
	},
];

export const appTheme = createTheme(colorPalette[0]);
export const appThemeDark = createTheme({
	palette: {
		mode: "dark",
		primary: {
			main: "#884384",
		},
		secondary: {
			main: "#FF6B01",
		},
		text: {
			primary: "#1B1B1B",
			secondary: "#fff",
		},
		background: {
			default: "#FDE3D0",
			dark: "#884384",
		},
	},
});
