import React from "react";
import { colors } from "../../../Utils/Colors";
import { icons } from "../../../Assets/Icons";
import CustomText from "../CustomText/CustomText";
import {} from "./style.css";

export const SizeGuideButton = () => {
	return (
		<div className="sizeGuideButton" style={{ borderColor: colors.colorB }}>
			<CustomText title="GUíA DE TALLAS" fontSize={10} color={colors.colorB} alignSelf={"center"} />
			<img alt="arrow" src={icons.rightArrow} />
		</div>
	);
};
