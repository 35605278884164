import React from "react";
import StoresRouter from "./Routes/stores";
import { StoreContextProvider } from "./Context/storeContext";
import { HelmetProvider } from "react-helmet-async";
import { BrowserRouter } from "react-router-dom";
import CssBaseline from "@mui/material/CssBaseline";
import { Experimental_CssVarsProvider as CssVarsProvider } from "@mui/material/styles";
import "./App.css";

function App() {
	return (
		<StoreContextProvider>
			<HelmetProvider>
				{/* <ThemeProvider theme={appTheme}> */}
					{/* <SnackbarProvider
						preventDuplicate
						maxSnack={3}
						anchorOrigin={{
							vertical: "top",
							horizontal: "right",
						}}> */}
						<BrowserRouter>
							<CssVarsProvider />
							<CssBaseline enableColorScheme />
							<StoresRouter></StoresRouter>
						</BrowserRouter>
					{/* </SnackbarProvider> */}
				{/* </ThemeProvider> */}
			</HelmetProvider>
		</StoreContextProvider>
	);
}

export default App;
