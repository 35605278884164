import React from "react";
import { colors } from "../../../Utils/Colors";
import { useSwiper } from "swiper/react";
import EastIcon from "@mui/icons-material/East";
import WestIcon from "@mui/icons-material/West";
import { useNavigate } from "react-router-dom";

const FilledRightCircle = ({ left = false, size = 1, top = null, back = false, background, bst = false }) => {
	const swiper = useSwiper();
	const navigate = useNavigate();

	if (bst) {
		return (
			<div
				onClick={() => (back ? navigate(-1) : left ? swiper.slidePrev() : swiper.slideNext())}
				style={{
					display: "flex",
					borderStyle: "solid",
					borderRadius: "50px",
					borderWidth: "1px",
					height: 55 / size,
					width: 55 / size,
					alignItems: "center",
					justifyContent: "center",
					cursor: "pointer",
					borderColor: "rgb(0, 0, 0)",
					float: left ? "left" : "right",
					position: "absolute",
					top: "20%",
					background: background ? background : "#fff",
					margin: "0px 20px",
					zIndex: 99,
					left: left ? 0 : "inherit",
					right: left ? "inherit" : 0,
				}}>
				{left ? <WestIcon style={{ color: "#000" }} /> : <EastIcon style={{ color: "#000" }} />}
			</div>
		);
	} else {
		return (
			<div
				onClick={() => (back ? navigate(-1) : left ? swiper.slidePrev() : swiper.slideNext())}
				style={{
					display: "flex",
					borderStyle: "solid",
					borderRadius: 50,
					borderWidth: 1,
					height: 55 / size,
					width: 55 / size,
					alignItems: "center",
					justifyContent: "center",
					cursor: "pointer",
					borderColor: colors.colorB,
					float: left ? "left" : "right",
					position: "relative",
					top: top ? top : "inherit",
					background: background ? background : "#fff",
					zIndex: 9,
					margin: "0 20px",
				}}>
				{left ? <WestIcon style={{ color: "#000" }} /> : <EastIcon style={{ color: "#000" }} />}
			</div>
		);
	}
};

export default FilledRightCircle;
