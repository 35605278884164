import React, { useContext, useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import {} from "../../../Assets/Style/DropDown.css";
import {} from "./style.css";

import Skeleton from "@mui/material/Skeleton";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { createFilterOptions } from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { useTheme } from "@mui/material/styles";
import { Popper } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";

import { StoreContext } from "../../../Context/storeContext";

class ErrorBoundary extends React.Component {
	constructor(props) {
		super(props);
		this.state = { hasError: false, errorInfo: null };
	}

	componentDidCatch(error, errorInfo) {
		// Catch errors in any components below and re-render with error message
		this.setState({
			error: error,
			errorInfo: errorInfo,
		});
		console.log(error, errorInfo);
	}

	render() {
		if (this.state.errorInfo) {
			// Error path
			return <Skeleton variant="rounded" animation="wave" width={210} height={40}></Skeleton>;
		}
		// Normally, just render children
		return this.props.children;
	}
}

const SearchComponent = (props) => {
	const theme = useTheme();
	const store_data = useContext(StoreContext);
	const navigate = useNavigate();
	const filterOptions = createFilterOptions({
		limit: 10,
		matchFrom: "any",
		stringify: (option) => option.title + option.skus + option.brand,
	});
	const [isOpenModal, setIsOpenModal] = useState(false);
	const [searchData, setSearchData] = useState();
	
	const handleSearchData = (event) => {
		setSearchData(event.target.value.trim());
	};
	const handleSearch = (event) => {
		event.preventDefault();
		if (searchData) {
			
			navigate(`/search/${searchData}`)
		}
	};

	useEffect(() => {
	  
		setIsOpenModal(false)
	}, [])
	

	let CustomPopper = (props) => {
		return (
			<Popper {...props} placement="bottom" style={{height: "auto", boxShadow: "rgba(0, 0, 0, 0.2) 0px 5px 5px -3px, rgba(0, 0, 0, 0.14) 0px 8px 10px 1px, rgba(0, 0, 0, 0.12) 0px 16px 14px 2px"}}>
				<Grid container justifyContent={"center"} style={{ backgroundColor: "#fff" }}>
					<Grid item style={{ color: theme.palette.text.primary, padding: "10px 0" }}>
						<h6 style={{ textAlign: "center", fontFamily: "ClashDisplay-Regular" }}>{store_data.translations["translate-all-pages-body-30"]}</h6>
					</Grid>
				</Grid>
				{props.children}
				{/* <Link to={`/search/${searchData ? searchData : ''}`} style={{textDecoration: "none"}} onMouseDown={() => navigate(`/search/${searchData ? searchData : ''}`)}>
					<div style={{ backgroundColor: theme.palette.primary.main, color: theme.palette.text.secondary, padding: "10px 0" }}>
						<h6 style={{ textAlign: "center", fontFamily: "ClashDisplay-Regular" }}>VER TODOS LOS RESULTADOS</h6>
					</div>
				</Link> */}
				<Link style={{textDecoration: "none"}} onMouseDown={handleSearch}>
					<div style={{ backgroundColor: theme.palette.secondary.main, color: theme.palette.text.secondary, padding: window.innerWidth <= 650 ? "20px 0" : "10px 0" }}>
						<h6 style={{ textAlign: "center", fontFamily: "ClashDisplay-Regular" }}>{store_data.translations["translate-all-pages-body-31"]}</h6>
					</div>
				</Link>
			</Popper>
		);
	};
	return (
		<>
			<Autocomplete
				freeSolo
				disablePortal
				filterOptions={filterOptions}
				id="navbar-input"
				style={window.innerWidth <= 650 ? { width: 140 } : { width: props.width ? props.width : "" }}
				options={store_data.filter_products}
				onOpen={() => setIsOpenModal(true)}
				onClose={() => setIsOpenModal(false)}
				getOptionLabel={(option) => option.title || option.skus || ""}
				renderOption={(props, option) => {
					props.className = props.className + " " + "navbar-search-option";
					return (
						<Box component="li" {...props}>
							<Link to={`/products/${option.handle}`} className="MuiTypography-root MuiTypography-inherit MuiTypography-noWrap css-guiuol">
								<Grid container spacing={2}>
									<Grid xs={4}>
										<img
											src={option.image == "/images/default/product_default.png" ? `https://admin.ventadirekta.com/images/default/product_default.png` : option.image}
											alt={option.title}
											style={{
												width: "60%",
												height: "auto",
											}}
										/>
									</Grid>
									<Grid xs={8} className="search-bar-product-info" style={{ fontFamily: "ClashDisplay-Regular" }}>
										<h5>{option.title}</h5>
										<h6 style={{ color: theme.palette.primary.main }}>{option.brand}</h6>
										<p>{option.max_price.format}</p>
									</Grid>
								</Grid>
							</Link>
						</Box>
					);
				}}
				sx={{ width: 300 }}
				renderInput={(params) => <InputRender params={params} isOpenModal={isOpenModal} setIsOpenModal={setIsOpenModal} onChange={handleSearchData} handleSearch={handleSearch}/>}
				PopperComponent={CustomPopper}
			/>
		</>
	);
};

const InputRender = ({ params, isOpenModal, setIsOpenModal, onChange, handleSearch }) => {
	params.InputProps.startAdornment = <InputAdornment position="start">{<SearchIcon />}</InputAdornment>;
	return (
		<form onSubmit={handleSearch}>
			<TextField {...params} variant="standard" onChange={onChange} name="search_input"/>
		</form>
	);
};

export default SearchComponent;
