import React from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Close } from "@mui/icons-material";
import { useTheme } from "@mui/material/styles";

import { StoreContext } from "../../../Context/storeContext";

export const Cart = (props) => {

	let cartItems = localStorage.getItem("cart") ? JSON.parse(localStorage.getItem("cart")) : [];
	if (props.action === "add") {
		if (cartItems.length > 0 && cartItems.find((item) => item.variant.sku == props.variant.sku)) {
			let item = cartItems.find((item) => item.variant.sku === props.variant.sku);

			item.quantity = parseFloat(item.quantity) + parseInt(props.quantity);
			item.total = parseFloat(item.price) * parseInt(item.quantity);
			localStorage.setItem("cart", JSON.stringify(cartItems));
			toast.success(`${item.title} +${parseInt(props.quantity)}`, {theme: "colored"});
			dispatchEvent(new Event("storage"));
		} else {
			const { action, className, ...filterCartItem } = props;
			toast.success(filterCartItem.title, {theme: "colored"});
			localStorage.setItem("cart", JSON.stringify([...cartItems, filterCartItem]));
			dispatchEvent(new Event("storage"));
		}

	} else if (props.action === "remove") {
		let cartItems = JSON.parse(localStorage.getItem("cart"));
		// cartItems = cartItems.filter(
		// 	(item) => item.variant.sku != props.sku
		// )
		props.setCartSubTotal((currentSubTotal) => currentSubTotal - cartItems.find((item) => item.variant.sku === props.sku).total);
		localStorage.setItem("cart", JSON.stringify(cartItems.filter((item) => item.variant.sku != props.sku)));
		// props.onClickEvent()
		// toast.success('', {theme: "colored"});
		dispatchEvent(new Event("storage"));
	}
};

// export const AddToCart = (props) => {
// 	return (
// 		<a
// 			className="col-6 buy-button btn btn-primary"
// 			onClick={(e) => {
// 				e.preventDefault();
// 				Cart(props);
// 			}}>
// 			Agregar al Carrito
// 		</a>
// 	);
// }
export const AddToCart = (props) => {
	const theme = useTheme();
	return (
		<a
			onClick={(e) => {
				e.preventDefault();
				Cart(props);
			}}>
			<div
				style={{
					paddingRight: 25,
					paddingLeft: 25,
					paddingTop: 5,
					paddingBottom: 5,
					backgroundColor: theme.palette.secondary.main,
					color: theme.palette.text.secondary,
					textDecoration: "none",
					fontFamily: "ClashDisplay-Regular",
					cursor: "pointer",
				}}>
				{props.text ? props.text : "+ADD"}
			</div>
		</a>
	);
};
export const RemoveFromCart = (props) => {
	const store_data = React.useContext(StoreContext);

	if (props.cross) {
		return (
			<Close
				fontSize="small"
				style={props.style}
				onClick={(e) => {
					e.preventDefault();
					Cart(props);
					props.onClickEvent();
				}}
			/>
		);
	} else {
		return (
			<a
				className="col-6 buy-button btn btn-danger"
				style={props.style}
				onClick={(e) => {
					e.preventDefault();
					Cart(props);
				}}>
				{store_data.translations["translate-cart-body-8"]}
			</a>
		);
	}
};
