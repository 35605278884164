import { Colors as ProductColors } from './ProductComponent/Colors/index';
import { Sizes as ProductSizes } from './ProductComponent/Sizes/index';
import { SizeGuideButton as ProductSizeGuideButton } from './SizeGuide/index';
import { AddtoCart as ProductAddtoCartButton } from './ProductComponent/AddtoCart/index';
import { Count as ProductCount } from './ProductComponent/Count/index';

import { NavBar as NavBarGeneral } from './NavBarGeneral/index'
import { FilterList as Filters } from './Filters/index'

export {
    ProductColors,
    ProductSizes,
    ProductSizeGuideButton,
    ProductAddtoCartButton,
    ProductCount,
    NavBarGeneral,
    Filters
}