import React from "react";
import { Button } from "@mui/material";
import CustomText from "../../Components/Common/CustomText/CustomText";
import { useTheme } from "@mui/material/styles";

import PhonelinkIcon from "@mui/icons-material/Phonelink";
import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";
import Modal from "@mui/material/Modal";
import Box from '@mui/material/Box';
import CircularProgress from "@mui/material/CircularProgress";
import { UseAddToHomescreenPrompt } from "./UseAddToHomeScreenPromptComponent.ts";

import apple_image from '../../Assets/Images/apple-pwa-add-to-home.webp'

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: window.innerWidth <= 425 ? "90vw" : "60vw",
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    display: "flex",
    justifyContent: "center",
    flexFlow: "column",
  };

export const Download = () => {
    const theme = useTheme();
	const [open, setOpen] = React.useState(false);
	const handleOpen = () => setOpen(true);
	const handleClose = () => setOpen(false);

	const [prompt, promptToInstall] = UseAddToHomescreenPrompt();
	const [isVisible, setVisibleState] = React.useState(false);
	const hide = () => setVisibleState(false);

	React.useEffect(
		() => {
		  if (prompt) {
			setVisibleState(true);
		  }
		},
		[prompt]
	  );

	if (isVisible) {
		
		return (
			<div style={{minHeight: "100vh", width: "100%", display: "inline-flex", alignItems: "center", justifyContent: "center", flexDirection: "column"}}>
				<div>
					<img src="https://ventadirekta.com/assets-landing/images/backgrounds/bg-section-three.png" alt="Download" style={{ width: "100%" }} />
				</div>
				<div
					style={{
						display: "inline-flex",
						width: "100%",
						justifyContent: "center",
						margin: "0 auto",
					}}>
					<Button variant="contained" style={{ margin: "0 5px" }} onClick={promptToInstall}>
						<PhonelinkIcon style={{ marginRight: "5px" }} /> WINDOWS & ANDROID
					</Button>
					<Button variant="contained" style={{ margin: "0 5px" }} onClick={handleOpen}>
						<PhoneIphoneIcon /> IOS
					</Button>
				</div>
				<Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
				<Box sx={style}>
					<CustomText title="Instala esta aplicación para un acceso más rápido y sencillo." textAlign="center" color={theme.palette.primary.main} fontSize={window.innerWidth <= 425 ? 16 : 22} />
					<img src={apple_image} alt="Apple PWA" style={{width: window.innerWidth <= 425 ? "100%" : "75%", margin: "auto"}} />
				</Box>
				</Modal>
			</div>
		);
	}else{
		
		let result = (
			<div style={{minHeight: "100vh", width: "100%", display: "inline-flex", alignItems: "center", justifyContent: "center", flexDirection: "column"}}>
				<CircularProgress style={{ color: "#000" }} />
			</div>
		)
		setTimeout(() => {

			result = (
				<div style={{minHeight: "100vh", width: "100%", display: "inline-flex", alignItems: "center", justifyContent: "center", flexDirection: "column"}}>
					<h5>Ocurrió un error, inténtalo de nuevo más tarde.</h5>
				</div>
			)
		}, 1000);

		return result;
	}
};
