import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Grid } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { useTheme } from "@mui/material/styles";
import Button from "@mui/material/Button";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import CustomText from "../../../Common/CustomText/CustomText";
import Spacer from "../../../Common/Spacer/Spacer";

import Box from "@mui/material/Box";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

import { Card, CardFooter, CardImage, CardImageBG } from "../../../Common/Card/Card";

import { StoreContext } from "../../../../Context/storeContext";
import { Filters } from "../../../Common/index";

const SearchMobile = (props) => {
	const theme = useTheme();
	const store_data = useContext(StoreContext);
	let urlParams = useParams();

	// let products = store_data.filter_products.filter((item) => item.title.toLowerCase().includes(urlParams.data.toLowerCase())).concat(store_data.filter_products.filter((item) => item.brand.toLowerCase().includes(urlParams.data.toLowerCase())));

	const [products, setProducts] = useState(store_data.filter_products.filter((item) => item.title.toLowerCase().includes(urlParams.data.toLowerCase())).concat(store_data.filter_products.filter((item) => item.brand.toLowerCase().includes(urlParams.data.toLowerCase()))));
	const [setFilters, setSetFilters] = useState(false);
	const [filterProducts, setFilterProducts] = useState([]);
	// let split_collection = chunk(setFilters ? filterProducts : products, 20);
	const [split_collection, setsplit_collection] = useState(chunk(setFilters ? filterProducts : products, 20));

	const [divHeight, setDivHeight] = useState(document.getElementById("productContainer") ? document.getElementById("productContainer").clientHeight : window.innerHeight);
	const [productSrcollState, setProductSrcollState] = useState(0);
	const [productSrcoll, setProductSrcoll] = useState(split_collection[0]);
	const [scrollLoader, setScrollLoader] = useState(false);
	const [load, setLoad] = useState(false);

	useEffect(() => {

		setLoad(false);
		setSetFilters(false)
		setProducts(store_data.filter_products.filter((item) => item.title.toLowerCase().includes(urlParams.data.toLowerCase())).concat(store_data.filter_products.filter((item) => item.brand.toLowerCase().includes(urlParams.data.toLowerCase()))));
	}, [urlParams]);

	useEffect(() => {
		
		setsplit_collection(chunk(setFilters ? filterProducts : products, 20));
		setDivHeight(document.getElementById("productContainer") ? document.getElementById("productContainer").clientHeight : window.innerHeight);
		setProductSrcollState(0);
		setProductSrcoll(chunk(setFilters ? filterProducts : products, 20)[0]);
		setTimeout(() => {
			setLoad(true);
		}, 2500);
	}, [products]);

	useEffect(() => {

		setsplit_collection(chunk(setFilters ? filterProducts : products, 20));
		setDivHeight(document.getElementById("productContainer") ? document.getElementById("productContainer").clientHeight : window.innerHeight);
		setProductSrcollState(0);
		setTimeout(() => {
			setProductSrcoll(chunk(setFilters ? filterProducts : products, 20)[0]);
		}, 1000);
	}, [filterProducts]);
	
	useEffect(() => {
		const onScroll = function () {
			// var clientHeight = document.getElementById("productContainer").clientHeight;
			if (window.innerHeight + window.scrollY >= divHeight) {
				if (productSrcollState + 1 <= split_collection.length - 1) {
					setScrollLoader(true);

					setTimeout(() => {
						setProductSrcoll(productSrcoll.concat(split_collection[productSrcollState + 1]));
						setProductSrcollState(productSrcollState + 1);
						setScrollLoader(false);
					}, 2000);
					setDivHeight(document.getElementById("productContainer").clientHeight);
				}
			}
		};
		window.addEventListener("scroll", onScroll);
		return () => window.removeEventListener("scroll", onScroll);
	}, [divHeight, productSrcoll, productSrcollState]);

	function chunk(arr, len) {
		let chunks = [],
			i = 0,
			n = arr.length;

		while (i < n) {
			chunks.push(arr.slice(i, (i += len)));
		}

		return chunks;
	}

	const [isDrawerOpen, setIsDrawerOpen] = useState(false);
	const [openModal, setOpenModal] = useState(false);

	const handleOpenModalFilters = () => {
		setIsDrawerOpen(true)
	};
	const handleCloseModalFilters = () => {
		setIsDrawerOpen(false);
	};

	function handleScrollLoad(){
		if (productSrcollState + 1 <= split_collection.length - 1) {
			setScrollLoader(true);

			setTimeout(() => {
				setProductSrcoll(productSrcoll.concat(split_collection[productSrcollState + 1]));
				setProductSrcollState(productSrcollState + 1);
				setScrollLoader(false);
			}, 2000);
			setDivHeight(document.getElementById("productContainer").clientHeight);
		}
	}

	return (
		<div style={{ backgroundColor: theme.palette.background.default }} id="productContainer">

			<button id="buttonFilterModal" onClick={handleOpenModalFilters} style={{display: "none"}}></button>

			<div style={{ display: "flex", flexDirection: "row", padding: "40px 14px 0" }}>
				<div style={{ display: "flex", flex: 11 }}>
					<div style={{ display: "flex" }}>
						<CustomText title={store_data.translations["translate-all-pages-footer-11"]} fontFamily={"ClashDisplay-SemiBold"} fontSize={window.innerWidth <= 700 ? 25 : 45} color={theme.palette.primary.main} />
					</div>
					<Spacer width={45} />
					<div
						style={{
							height: 1,
							width: "100%",
							backgroundColor: theme.palette.text.primary,
							alignSelf: "center",
						}}></div>
				</div>
			</div>

			{load && products ? (
				<Grid container justifyContent={"center"} flexDirection={"row"}>
					<Grid item xs={12} padding={"0 14px 5px"}>
						<p style={{fontFamily: "ClashDisplay-Regular", fontSize: 12}}>Products: <span style={{fontFamily: "ClashDisplay-SemiBold", fontSize: 12}}>{setFilters ? filterProducts.length : products.length}</span></p>
					</Grid>
					<FormatProducts />
					{productSrcollState + 1 <= split_collection.length - 1 && !scrollLoader ? (

						<Button variant="contained" color="secondary" style={{margin: "20px auto 0"}} onClick={handleScrollLoad}>{store_data.translations["translate-all-pages-body-25"]}</Button>
					) : ''}
				</Grid>
			) : (
				<div
					style={{
						display: "inline-flex",
						justifyContent: "center",
						alignItems: "center",
						width: "100%",
						height: "160px",
					}}>
					<CircularProgress style={{ color: theme.palette.primary.main }} />
				</div>
			)}

<			SwipeableDrawer anchor="left" open={isDrawerOpen} sx={{}} onClose={() => setIsDrawerOpen(false)} onOpen={() => setIsDrawerOpen(true)}>
				<Box p={2} role="presentation" textAlign="center" backgroundColor={theme.palette.background.default} height={"100%"} padding={'20px 0'} width={350}>
					<Filters products={store_data.filter_products.filter((item) => item.title.toLowerCase().includes(urlParams.data.toLowerCase())).concat(store_data.filter_products.filter((item) => item.brand.toLowerCase().includes(urlParams.data.toLowerCase())))} filterProducts={filterProducts} setFilterProducts={setFilterProducts} setSetFilters={setSetFilters} setLoad={setLoad} />
				</Box>
			</SwipeableDrawer>
		</div>
	);

	function FormatProducts() {
		let products_arr = [];

		if (productSrcoll && productSrcoll.length > 0) {
			for (const item of productSrcoll) {
				products_arr.push(
					<Grid item xs={12} sm={6} key={item._id} padding={"0 14px"} marginBottom={"40px"}>
						<CardImageBG image={item.image} height={"auto"} id={item.id} title={item.title} handle={item.handle} price={item.min_price.format} light={props.light} product={item} />
						<Spacer height={10} />
						<CardFooter product={item} index={item._id} id={item.id} sku={item.skus[0]} price={item.min_price.format} light={props.light} />
					</Grid>
				);
			}
		} else {
			products_arr.push(
				<Grid item xs={12}>
					<h5 style={{ fontFamily: "ClashDisplay-Regular", fontSize: 15, textAlign: "center", margin: "70px 0" }}>{store_data.translations["translate-all-pages-body-26"]}</h5>
				</Grid>
			);
		}

		return products_arr;
	}
};

export default SearchMobile;
