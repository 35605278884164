import React from "react";
import { Link } from "react-router-dom";
import { colors } from "../../../../Utils/Colors";
import CustomButton from "../../CustomButton/CustomButton";
import {} from "./style.css";

export const AddtoCart = () => {
	return (
		<div className="addToCartButton">
			<Link to="/cart">
				<CustomButton title="+ ADD TO CART" fontSize={15} justifyContent={"center"} borderRadius={"0"} width={150} height={40} color={colors.white} backgroundColor={colors.colorC} fontFamily={"ClashDisplay-Light"} />
			</Link>
		</div>
	);
};
