import "./style.css";
import "../../Assets/Style/font.css";
import React, { useState, useContext } from "react";
import { Helmet } from "react-helmet-async";
import { useParams, Link } from "react-router-dom";
import CollectionMobile from "../../Components/Pages/Collection/mobile/CollectionMobile";
import { CollectionWeb } from "../../Components/Pages/Collection/web/CollectionWeb";
import { StoreContext } from "../../Context/storeContext";

export const Collections = (props) => {
	let urlParams = useParams();
	const store_data = useContext(StoreContext);
	const [open, setOpen] = useState(false);
	const [light, setLight] = useState(true);
	const [on, setOn] = useState(false);
	const [isDrawerOpen, setIsDrawerOpen] = React.useState(false);
	
  return (
		<div>
			<Helmet>
				<title>Collection</title>
			</Helmet>
			{window.innerWidth <= 1024 ? <CollectionMobile isDrawerOpen={isDrawerOpen} setIsDrawerOpen={setIsDrawerOpen} on={on} light={light} setLight={setLight}/> : <CollectionWeb isDrawerOpen={isDrawerOpen} setIsDrawerOpen={setIsDrawerOpen} on={on} light={light} setLight={setLight} />}
		</div>
	);
};
