import React from "react";
import { Box, Button, Container, Typography } from "@mui/material";
import Grid from "@mui/material/Grid";

export const Error = () => {
	return (
		<Box
			sx={{
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
				minHeight: "100vh",
			}}>
			<Container maxWidth="md">
				<Grid container direction="row" justifyContent="center" alignItems="center" spacing={2}>
					<Grid xs={6}>
						<Typography variant="h6" style={{ fontFamily: "ClashDisplay-Regular" }}>
							Ocurrió un error, recarga la página o intentalo más tarde.
						</Typography>
					</Grid>
					<Grid xs={6}>
						<img src="https://ventadirekta.com/assets/images/logo/logo-ventadirekta.webp" alt="" width={500} height={"auto"} />
					</Grid>
				</Grid>
			</Container>
		</Box>
	);
};
