import React, { useState, useEffect, useContext } from "react";
import { Link, useParams } from "react-router-dom";
import { colors as Ucolors } from "../../../Utils/Colors";
import { row } from "../../../Utils/CommonStyles";
import CustomText from "../CustomText/CustomText";
import { ProductColors, ProductSizes } from "../index";
import Spacer from "../Spacer/Spacer";
import Grid from "@mui/material/Grid";
import { AddToCart } from "../productActions/productActions";
import { StoreContext } from "../../../Context/storeContext";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation, Lazy } from "swiper";
import CircularProgress from '@mui/material/CircularProgress';
import {} from "./style.css";

export const Card = (props) => {
	return (
		<div style={{ margin: "20px 0" }}>
			{props.footer === "up" ? (
				<>
					<CardFooter product={props.product} light={true} price={props.price} />
					<Spacer height={10} />
				</>
			) : (
				""
			)}
			<Link
				to={`/products/${props.handle}`}
				style={{
					textDecoration: "none",
					color: props.light ? Ucolors.black : Ucolors.white,
					// backgroundImage: `url(${props.image})`,
					backgroundSize: "cover",
					width: "100%",
					display: "inline-flex",
					height: "60vh",
				}}>
				<Swiper id="card-product-collection" spaceBetween={0} slidesPerView={1} loop="false" allowTouchMove={true} modules={[Pagination, Lazy]} pagination={true} lazy={true}>
					{props.product.images.map((item, index) => (
						<SwiperSlide
							key={index}
							style={{
								display: "inline-flex",
								justifyContent: "center"
							}}>
							<img src={item.src} alt="" style={{ height: "100%", width: "auto" }} loading="lazy" />
						</SwiperSlide>
					))}
				</Swiper>
			</Link>
			{props.footer === "down" ? (
				<>
					<Spacer height={10} />
					<CardFooter product={props.product} light={true} price={props.price} />
				</>
			) : (
				""
			)}
		</div>
	);
};

export const CardFooter = ({ index, product, ...props }) => {
	const store_data = useContext(StoreContext);

	let variants = product?.variants.filter((item) => item.inventory_quantity > 0);
	let first_variant = product ? variants[0] : "";
	const [productVariant, setProductVariant] = useState(product ? first_variant.sku : "");
	const [maxQuantity, setMaxQuantity] = useState(product ? first_variant.inventory_quantity : "");
	let compare_at_price = product?.variants.find((item) => item.sku == productVariant).compare_at_price;

	if (product) {
		return (
			<div>
				<Grid
					container
					style={{
						...row,
						justifyContent: "space-between",
						alignItems: "center",
					}}>
					<Grid
						item
						xs={12}
						style={{
							display: "flex",
							flexDirection: "row",
							// justifyContent: "center",
							alignItems: "center",
						}}>
						<Link
							to={`/products/${product.handle}`}
							style={{
								textDecoration: "none",
							}}>
							<CustomText
								fontSize={16}
								title={product.title}
								titleTL={product.title}
								textAlign={"left"}
								fontFamily={"ClashDisplay-SemiBold"}
								color={props.light ? Ucolors.black : Ucolors.white}
								wrap={true}
								lines={2}
								// color={Ucolors.white}
							/>
						</Link>
					</Grid>
					<Grid item xs={12}>
						<Link
							to={`/brands/${product.brand.toLowerCase()}`}
							style={{
								textDecoration: "none",
							}}>
							<CustomText
								fontSize={16}
								title={product.brand}
								textAlign={"left"}
								color={props.light ? Ucolors.black : Ucolors.white}
								// color={Ucolors.white}
							/>
						</Link>
					</Grid>
					<Grid
						item
						xs={12}
						style={{
							display: "flex",
							flexDirection: "row",
							// justifyContent: "center",
							alignItems: "center",
						}}>
						<Grid container>
							<Grid item xs={6}>
								<ProductSizes light={props.light} variants={product.variants} productVariant={productVariant} setProductVariant={setProductVariant} setMaxQuantity={setMaxQuantity} />
							</Grid>
							<Grid item xs={6}>
								<ProductColors variants={product.variants} productVariant={productVariant} setProductVariant={setProductVariant} setMaxQuantity={setMaxQuantity} />
							</Grid>
						</Grid>
					</Grid>
				</Grid>
				<div
					style={{
						...row,
						justifyContent: "space-between",
						alignItems: "center",
					}}>
					<div>
						{index === 0 ? (
							<s
								style={{
									color: props.light ? Ucolors.black : Ucolors.white,
									fontFamily: "ClashDisplay-Regular",
								}}>
								{compare_at_price && compare_at_price > 0 ? compare_at_price : ""}
							</s>
						) : null}
						{/* {console.log(product)} */}
						<div style={{ marginTop: index === 0 ? 0 : 15 }} />
						<CustomText fontSize={16} title={props.price} marginTop={index === 0 ? 50 : 50} color={props.light ? Ucolors.black : Ucolors.white} />
						{/* <span style={{marginTop:50}} /> */}
					</div>
					{product ? (
						<AddToCart
							action="add"
							quantity={1}
							id={product._id}
							shopify_id={product.shopify_id}
							title={product.title}
							brand={product.brand}
							description={product.description.replace("<p>", "").replace("</p>", "").split("<ul>")[0]}
							price={product.max_price.number}
							total={product.max_price.number * 1}
							handle={product.handle}
							variant={{
								sku: productVariant,
								color: product.variants.find((item) => item.sku == productVariant).option2,
								size: product.variants.find((item) => item.sku == productVariant).option1,
								variant_id: product.variants.find((item) => item.sku == productVariant).id,
							}}
							image={product.image == "/images/default/product_default.png" ? product.images.find((item) => item.src !== "/images/default/product_default.png").src : product.image}
						/>
					) : (
						""
					)}
					{/* <div
				style={{
					paddingRight: 25,
					paddingLeft: 25,
					paddingTop: 5,
					paddingBottom: 5,
					backgroundColor: Ucolors.colorC,
					color: Ucolors.white,
					textDecoration: "none",
					fontFamily: "ClashDisplay-Regular",
					cursor: "pointer",
				}}
				>
				+ADD
				</div> */}
				</div>
			</div>
		);
	}
};
export const CardImage = ({ height, image, ...props }) => {
	return (
		<Link
			to={`/products/${props.handle}`}
			style={{
				textDecoration: "none",
				color: props.light ? Ucolors.black : Ucolors.white,
			}}>
			<img src={image == "/images/default/product_default.png" ? props.product.images.find((item) => item.src !== "/images/default/product_default.png").src : image} alt="" style={{ height: height || "auto", width: "100%" }} />
		</Link>
	);
};

export const CardImageBG = ({ height, image, ...props }) => {

	const [imageLoaded, setImageLoaded] = useState(false);

	return (
		<Link
			to={`/products/${props.handle}`}
			style={{
				textDecoration: "none",
				color: props.light ? Ucolors.black : Ucolors.white,
				// backgroundImage: `url(${image == "/images/default/product_default.png" ? props.product.images.find((item) => item.src !== "/images/default/product_default.png").src : image})`,
				backgroundSize: "cover",
				width: "100%",
				display: "inline-flex",
				height: "40vh",
			}}>
			<Swiper id="card-product-collection" spaceBetween={0} slidesPerView={1} loop="false" preloadImages={false} allowTouchMove={true} modules={[Pagination, Lazy]} pagination={true} lazy={true}>
				{props.product.images.map((item, index) => (
					<SwiperSlide
						key={index}
						loading="lazy"
						style={{
							display: "inline-flex",
							justifyContent: "center",
							alignItems: "center",
							background: "#fff",
							contentVisibility: "auto"
						}}>
						{imageLoaded ? '' : (
							<CircularProgress style={{position: "absolute"}}/>
						)}
						<img src={`${item.src}&width=400`} alt="" style={{ height: "100%", width: "auto", margin: "auto" }} loading="lazy" onLoad={() => setImageLoaded(true)}/>
					</SwiperSlide>
				))}
			</Swiper>
		</Link>
	);
};

export const CardImage1 = ({ height, image, ...props }) => {
	return (
		<Link
			to={`/products/${props.handle}`}
			style={{
				textDecoration: "none",
				color: props.light ? Ucolors.black : Ucolors.white,
			}}>
			<img src={image == "/images/default/product_default.png" ? props.product.images.find((item) => item.src !== "/images/default/product_default.png").src : image} alt="" style={{ height: height || "auto", width: "100%" }} />
		</Link>
	);
};
