import React, {useState, useEffect, useContext} from "react";
import { Outlet } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { Helmet } from "react-helmet-async";

import Navbar from "../../Components/Common/Navbar/Navbar";
import Footer from "../../Components/Common/Footer/Footer";
import BottomBarNavigation from "../../Components/Common/BottomNavigation/BottomNavigation";
import { MuiDrawer } from "../../Components/Common/Sidebar/Sidebar";

import { StoreContext } from "../../Context/storeContext";

const validProductionAdminUrl = function (url = '') {
	if (process.env.REACT_APP_NODE_ENV === "TEST") {
		return `http://localhost:3030${ url }`;
	} else {
		return `https://admin.ventadirekta.com${ url }`;
	}
};

export const Index = () => {
	useEffect(() => {
		//  scroll to top on page load
		window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
	}, []);
    const store_data = useContext(StoreContext);
	// console.log(store_data.manifest)

    const [on, setOn] = useState(false);
    const [light, setLight] = useState(true);

    const [isDrawerOpen, setIsDrawerOpen] = useState(false);

    return(
        <>
            <ToastContainer />
            <Helmet>
                <link rel="manifest" href={validProductionAdminUrl(store_data.manifest)} />
            </Helmet>

            <Navbar on={on} setOn={setOn} light={light} setLight={setLight} />

            <MuiDrawer isDrawerOpen={isDrawerOpen} setIsDrawerOpen={setIsDrawerOpen} />

            <Outlet />
            <Footer />
            {window.innerWidth <= 1024 ? <BottomBarNavigation isDrawerOpen={isDrawerOpen} setIsDrawerOpen={setIsDrawerOpen}/> : ""}
        </>
    )
}