import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Grid } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { useTheme } from "@mui/material/styles";
import { colors } from "../../../../Utils/Colors";
import CustomText from "../../../Common/CustomText/CustomText";
import Spacer from "../../../Common/Spacer/Spacer";
import NewArrivals from "../../../Common/NewArrivals/NewArrivals";

import image1 from "../../../../Assets/Collections/Banners/Mobile/Brands/FUNAT-VERTICAL.jpg";
import image2 from "../../../../Assets/Collections/Banners/Mobile/Brands/FLEXMEE-VERTICAL.jpg";
import image3 from "../../../../Assets/Collections/Banners/Mobile/Brands/DIANE-AND-GEORDI-VERTICAL.jpg";
import image4 from "../../../../Assets/Collections/Banners/Mobile/Brands/LATY-ROSE-VERTICAL.jpg";
import image5 from "../../../../Assets/Collections/Banners/Mobile/Brands/DIANE-AND-GEORDI-VERTICAL.jpg";
import image6 from "../../../../Assets/Collections/Banners/Mobile/Brands/LOWLA-VERTICAL.jpg";
import image7 from "../../../../Assets/Collections/Banners/Mobile/Brands/SONRYSE-VERTICAL.jpg";
import image8 from "../../../../Assets/Collections/Banners/Mobile/Brands/DIANE-AND-GEORDI-VERTICAL.jpg";
import image9 from "../../../../Assets/Collections/Banners/Mobile/Brands/DIANE-AND-GEORDI-VERTICAL.jpg";
import image10 from "../../../../Assets/Collections/Banners/Mobile/Brands/BLING-SHAPERS-VERTICAL.jpg";
import image11 from "../../../../Assets/Collections/Banners/Mobile/Brands/MARIAE-VERTICAL.jpg";
import image12 from "../../../../Assets/Collections/Banners/Mobile/Brands/DIANE-AND-GEORDI-VERTICAL.jpg";
import image13 from "../../../../Assets/Collections/Banners/Mobile/Brands/DRAXY-VERTICAL.jpg";
import image14 from "../../../../Assets/Collections/Banners/Mobile/Brands/BE-SHAPY-VERTICAL.jpg";
import image15 from "../../../../Assets/Collections/Banners/Mobile/Brands/LOWLA-VERTICAL.jpg";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

import { CardFooter, CardImageBG } from "../../../Common/Card/Card";

import { StoreContext } from "../../../../Context/storeContext";

const BrandMobile = (props) => {
	const theme = useTheme();
	const store_data = useContext(StoreContext);
	let urlParams = useParams();
	let collection_data = store_data.filter_products.filter((item) => item.brand.toLowerCase() === urlParams.brand.toLowerCase());
	let products = store_data.filter_products.filter((item) => item.brand.toLowerCase() === urlParams.brand.toLowerCase());

	const [filterProducts, setFilterProducts] = useState([]);
	let split_collection = chunk(filterProducts.length === 0 ? products : filterProducts, 20);

	const [divHeight, setDivHeight] = useState(document.getElementById("testDiv") ? document.getElementById("testDiv").clientHeight : window.innerHeight);
	const [productSrcollState, setProductSrcollState] = useState(0);
	const [productSrcoll, setProductSrcoll] = useState(split_collection[0]);
	const [scrollLoader, setScrollLoader] = useState(false);
	const [load, setLoad] = useState(false);

	useEffect(() => {
		setLoad(false);
		setDivHeight(document.getElementById("testDiv") ? document.getElementById("testDiv").clientHeight : window.innerHeight);
		setProductSrcollState(0);
		setProductSrcoll(split_collection[0]);
		setTimeout(() => {
			setLoad(true);
		}, 1000);
	}, [urlParams, filterProducts]);
	useEffect(() => {
		const onScroll = function () {
			// var clientHeight = document.getElementById("testDiv").clientHeight;
			if (window.innerHeight + window.scrollY >= divHeight) {
				if (productSrcollState + 1 < split_collection.length - 1) {
					setScrollLoader(true);

					setTimeout(() => {
						setProductSrcoll(productSrcoll.concat(split_collection[productSrcollState + 1]));
						setProductSrcollState(productSrcollState + 1);
						setScrollLoader(false);
					}, 2000);
					setDivHeight(document.getElementById("testDiv").clientHeight);
				}
			}
		};
		window.addEventListener("scroll", onScroll);
		return () => window.removeEventListener("scroll", onScroll);
	}, [divHeight, productSrcoll, productSrcollState]);

	function chunk(arr, len) {
		let chunks = [],
			i = 0,
			n = arr.length;

		while (i < n) {
			chunks.push(arr.slice(i, (i += len)));
		}

		return chunks;
	}

	let brand_images = {
		"funat labs": image1,
		flexmee: image2,
		"diane & geordi": image3,
		"laty rose": image4,
		"fajas myd": image5,
		LOWLA: image6,
		sonryse: image7,
		romanza: image8,
		"fajas salome": image9,
		"bling shapers": image10,
		"mariaE fajas": image11,
		upLady: image12,
		uplady: image12,
		draxy: image13,
		"be shapy": image14,
		lowla: image15,
	};
	let banner_image = brand_images[urlParams.brand];
	return (
		<div style={{ backgroundColor: theme.palette.background.default }} id="testDiv">
			<div style={{ width: "100%", height: "55vh", backgroundImage: `url(${banner_image})`, backgroundSize: "cover" }}>
				{/* <img
					style={{
						width: "100%",
						height: "auto",
						position: "absolute",
					}}
					src={collection_images[urlParams.collection]}
					alt=""
				/> */}
				<div
					style={{
						position: "absolute",
						width: "auto",
						padding: 40,
						display: "flex",
						flexDirection: "column",
						justifyContent: "flex-end",
						height: 400,
					}}>
					<div>
						<CustomText title={urlParams.collection ? store_data.collections_all.filter((item) => item.handle === urlParams.collection)[0].name : urlParams.brand ? urlParams.brand : ""} fontFamily={"ClashDisplay-SemiBold"} fontSize={33} color={colors.white} />
					</div>
				</div>
			</div>

			<div
				style={{
					width: window.innerWidth <= 1000 ? "100%" : "auto",
					paddingRight: window.innerWidth <= 1000 ? "" : 100,
					paddingLeft: window.innerWidth <= 1000 ? "" : 100,
					backgroundColor: theme.palette.background.default,
				}}>
				{/* <div style={{ width: "100%", }}> */}
				<Spacer height={window.innerWidth <= 1000 ? 80 : ""} />
				{load ? <NewArrivals light={true} /> : ""}
			</div>

			{load ? (
				<Grid container>
					<FormatProducts />
				</Grid>
			) : (
				<div
					style={{
						display: "inline-flex",
						justifyContent: "center",
						alignItems: "center",
						width: "100%",
						height: "160px",
					}}>
					<CircularProgress style={{ color: theme.palette.primary.main }} />
				</div>
			)}
		</div>
	);

	function FormatProducts() {
		let products_arr = [];

		if (productSrcoll && productSrcoll.length > 0) {
			for (const item of productSrcoll) {
				products_arr.push(
					<Grid item xs={12} sm={6} key={item._id} padding={"0 14px"} marginBottom={"40px"}>
						<CardImageBG image={item.image} height={"auto"} id={item.id} title={item.title} handle={item.handle} price={item.min_price.format} light={props.light} product={item} />
						<Spacer height={10} />
						<CardFooter product={item} index={item._id} id={item.id} sku={item.skus[0]} price={item.min_price.format} light={props.light} />
					</Grid>
				);
			}
		} else {
			products_arr.push(
				<Grid item xs={12}>
					<h5 style={{ fontFamily: "ClashDisplay-Regular", fontSize: 15, textAlign: "center", margin: "70px 0" }}>{store_data.translations["translate-all-pages-body-26"]}</h5>
				</Grid>
			);
		}

		return products_arr;
	}
};

export default BrandMobile;
