import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Grid } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { useTheme } from "@mui/material/styles";
import CustomText from "../../../Common/CustomText/CustomText";

import Spacer from "../../../Common/Spacer/Spacer";
import { Card, CardFooter, CardImage, CardImageBG } from "../../../Common/Card/Card";
import { Button } from "@mui/material";

import { StoreContext } from "../../../../Context/storeContext";
import { Filters } from "../../../Common/index";

export const SearchWeb = (props) => {
	const theme = useTheme();
	const store_data = useContext(StoreContext);
	let urlParams = useParams();

	// let products = store_data.filter_products.filter((item) => item.title.toLowerCase().includes(urlParams.data.toLowerCase())).concat(store_data.filter_products.filter((item) => item.brand.toLowerCase().includes(urlParams.data.toLowerCase())));

	const [products, setProducts] = useState(store_data.filter_products.filter((item) => item.title.toLowerCase().includes(urlParams.data.toLowerCase())).concat(store_data.filter_products.filter((item) => item.brand.toLowerCase().includes(urlParams.data.toLowerCase()))));
	const [setFilters, setSetFilters] = useState(false);
	const [filterProducts, setFilterProducts] = useState([]);
	const [split_collection, setsplit_collection] = useState(chunk(setFilters ? filterProducts : products, 20));

	// let split_collection = chunk(products, 20);

	const [divHeight, setDivHeight] = useState(document.getElementById("productContainer") ? document.getElementById("productContainer").clientHeight : window.innerHeight);
	const [productSrcollState, setProductSrcollState] = useState(0);
	const [productSrcoll, setProductSrcoll] = useState(split_collection[0]);
	const [scrollLoader, setScrollLoader] = useState(false);
	const [load, setLoad] = useState(false);

	useEffect(() => {

		setLoad(false);
		setSetFilters(false)
		setProducts(store_data.filter_products.filter((item) => item.title.toLowerCase().includes(urlParams.data.toLowerCase())).concat(store_data.filter_products.filter((item) => item.brand.toLowerCase().includes(urlParams.data.toLowerCase()))));
	}, [urlParams.data]);

	useEffect(() => {
		
		setsplit_collection(chunk(setFilters ? filterProducts : products, 20));
		setDivHeight(document.getElementById("productContainer") ? document.getElementById("productContainer").clientHeight : window.innerHeight);
		setProductSrcollState(0);
		setProductSrcoll(chunk(setFilters ? filterProducts : products, 20)[0]);
		setTimeout(() => {
			setLoad(true);
		}, 2500);
	}, [products]);

	useEffect(() => {
		setLoad(false);

		setsplit_collection(chunk(setFilters ? filterProducts : products, 20));
		setDivHeight(document.getElementById("productContainer") ? document.getElementById("productContainer").clientHeight : window.innerHeight);
		setProductSrcollState(0);
		setTimeout(() => {
			setProductSrcoll(chunk(setFilters ? filterProducts : products, 20)[0]);
			setLoad(true);
		}, 1000);
	}, [filterProducts]);
	
	useEffect(() => {
		const onScroll = function () {
			// var clientHeight = document.getElementById("productContainer").clientHeight;
			if (window.innerHeight + window.scrollY >= divHeight) {
				if (productSrcollState + 1 <= split_collection.length - 1) {
					setScrollLoader(true);

					setTimeout(() => {
						setProductSrcoll(productSrcoll.concat(split_collection[productSrcollState + 1]));
						setProductSrcollState(productSrcollState + 1);
						setScrollLoader(false);
					}, 2000);
					setDivHeight(document.getElementById("productContainer").clientHeight);
				}
			}
		};
		window.addEventListener("scroll", onScroll);
		return () => window.removeEventListener("scroll", onScroll);
	}, [divHeight, productSrcoll, productSrcollState]);

	function chunk(arr, len) {
		let chunks = [],
			i = 0,
			n = arr.length;

		while (i < n) {
			chunks.push(arr.slice(i, (i += len)));
		}

		return chunks;
	}

	function handleScrollLoad(){
		if (productSrcollState + 1 <= split_collection.length - 1) {
			setScrollLoader(true);

			setTimeout(() => {
				setProductSrcoll(productSrcoll.concat(split_collection[productSrcollState + 1]));
				setProductSrcollState(productSrcollState + 1);
				setScrollLoader(false);
			}, 2000);
			setDivHeight(document.getElementById("productContainer").clientHeight);
		}
	}

	return (
		<Grid container justifyContent="flex-end">
			<Grid
				item
				xs={2}
				style={{
					// position: "fixed",
					left: "0",
					width: "inherit",
					padding: "0 20px",
					backgroundColor: theme.palette.background.default,
				}}>
					<Filters products={store_data.filter_products.filter((item) => item.title.toLowerCase().includes(urlParams.data.toLowerCase())).concat(store_data.filter_products.filter((item) => item.brand.toLowerCase().includes(urlParams.data.toLowerCase())))} filterProducts={filterProducts} setFilterProducts={setFilterProducts} setSetFilters={setSetFilters} setLoad={setLoad} />
				</Grid>
			<Grid item xs={10}>
				<div
					style={{
						padding: 0,
						paddingRight: 90,
						backgroundColor: theme.palette.background.default,
						paddingTop: 0,
						marginTop: 0,
					}}>
					<div>
						<div style={{ display: "flex", flexDirection: "row" }}>
							<div style={{ display: "flex", flex: 1 }}></div>
							<div style={{ display: "flex", flex: 11 }}>
								<div style={{ display: "flex" }}>
									<CustomText title={store_data.translations["translate-all-pages-footer-11"]} textTransform="uppercase" fontFamily={"ClashDisplay-SemiBold"} fontSize={window.innerWidth <= 700 ? 25 : 45} color={theme.palette.primary.main} />
								</div>
								<Spacer width={45} />
								<div
									style={{
										height: 1,
										width: "100%",
										backgroundColor: theme.palette.text.primary,
										alignSelf: "center",
									}}></div>
							</div>
						</div>
					</div>

					<Spacer height={60} />

					<Grid
						id="productContainer"
						container
						rowSpacing={{ xs: 5, sm: 2, md: 3 }}
						columnSpacing={{ xs: 1, sm: 2, md: 3 }}
						sx={{
							backgroundColor: theme.palette.background.default,
						}}>
						{load && products ? (
							<>
								<Grid item xs={12}>
									<p style={{fontFamily: "ClashDisplay-Regular", fontSize: 12}}>Products: <span style={{fontFamily: "ClashDisplay-SemiBold", fontSize: 12}}>{setFilters ? filterProducts.length : products.length}</span></p>
								</Grid>
								<FormatProducts />
								{productSrcollState + 1 <= split_collection.length - 1 && !scrollLoader ? (

									<Button variant="contained" color="secondary" style={{margin: "20px auto 0"}} onClick={handleScrollLoad}>{store_data.translations["translate-all-pages-body-25"]}</Button>
								) : ''}
							</>
						) : (
							<div
								style={{
									display: "inline-flex",
									justifyContent: "center",
									alignItems: "center",
									width: "100%",
									height: "160px",
								}}>
								<CircularProgress style={{ color: theme.palette.primary.main }} />
							</div>
						)}
					</Grid>
					{load && scrollLoader ? (
						<div
							style={{
								display: "inline-flex",
								justifyContent: "center",
								alignItems: "center",
								width: "100%",
								height: "160px",
							}}>
							<CircularProgress style={{ color: theme.palette.primary.main }} />
						</div>
					) : (
						""
					)}

					<Spacer height={60} />
				</div>
			</Grid>
		</Grid>
	);

	function FormatProducts() {
		let key = 0;
		let products_arr = [];
		let productLength = 0;

		if (productSrcoll && productSrcoll.length > 0) {
			for (const item of productSrcoll) {
				if (productLength < 8) {
					products_arr.push(
						<Grid item xs={6} sm={4} md={3} key={key}>
							<CardImageBG image={item.image} height={"auto"} id={item.id} title={item.title} handle={item.handle} price={item.min_price.format} light={props.light} product={item} />
							<Spacer height={10} />
							<CardFooter product={item} index={item._id} id={item.id} sku={item.skus[0]} price={item.min_price.format} light={props.light} />
						</Grid>
					);
					productLength++;
					key++;
				} else if (productLength === 8) {
					products_arr.push(
						<Grid item xs={6} key={item._id}>
							<Card footer={"up"} product={item} image={item.image} id={item.id} handle={item.handle} price={item.min_price.format} />
						</Grid>
					);
					productLength++;
					key++;
				} else {
					products_arr.push(
						<Grid item xs={6} key={item._id}>
							<Card footer={"down"} product={item} image={item.image} id={item.id} handle={item.handle} price={item.min_price.format} />
						</Grid>
					);
					productLength = 0;
					key++;
				}
			}
		} else {
			products_arr.push(
				<Grid item xs={12}>
					<h5 style={{ fontFamily: "ClashDisplay-Regular", fontSize: 15, textAlign: "center", margin: "70px 0" }}>{store_data.translations["translate-all-pages-body-26"]}</h5>
				</Grid>
			);
		}

		return products_arr;
	}
};
