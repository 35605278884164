import { Container, fontSize } from "@mui/system";
import { useTheme } from "@mui/material/styles";
import React, { useState, useContext } from "react";
import { Link } from "react-router-dom";
import CustomText from "../CustomText/CustomText";
import SearchComponent from "../SearchComponent/SearchComponent";
import Spacer from "../Spacer/Spacer";
import { colors } from "../../../Utils/Colors";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import Badge from "@mui/material/Badge";
import Menu from "@mui/material/Menu";
import MenuList from "@mui/material/MenuList";
import MenuItem from "@mui/material/MenuItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import Button from "@mui/material/Button";

import { StoreContext, LanguageContext } from "../../../Context/storeContext";

export const NavbarMobile = (props) => {
	const theme = useTheme();
	const store_data = useContext(StoreContext);
	const { language, setLanguage } = useContext(LanguageContext);
	let collections_data = JSON.parse(store_data.collections);

	const [open, setOpen] = useState(false);

	const [anchorEl, setAnchorEl] = React.useState(null);
	const openMenu = Boolean(anchorEl);
	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	const [cartLength, setcartLength] = useState(
		JSON.parse(localStorage.getItem("cart")).length > 0
			? JSON.parse(localStorage.getItem("cart"))
					.map((item) => item.quantity)
					.reduce((a, b) => a + b)
			: 0
	);

	window.addEventListener("storage", (e) => {
		setcartLength(
			JSON.parse(localStorage.getItem("cart")).length > 0
				? JSON.parse(localStorage.getItem("cart"))
						.map((item) => item.quantity)
						.reduce((a, b) => a + b)
				: 0
		);
	});

	return (
		<>
			<div style={{ backgroundColor: theme.palette.text.secondary, paddingTop: 5 }}>
				<Spacer height={10} />
				<div
					style={{
						display: "flex",
						justifyContent: "space-around",
						width: "auto",
					}}>
					<div>
						<Link to="/" style={{ textDecoration: "none" }}>
							<CustomText title={store_data.store_name.length > 4 ? store_data.initials : store_data.store_name} fontFamily="ClashDisplay-SemiBold" textTransform="capitalize" />
						</Link>
					</div>
					<SearchComponent setOpen={setOpen} open={open} />
					<div>
						<Button id="basic-button" aria-controls={open ? "basic-menu" : undefined} aria-haspopup="true" aria-expanded={open ? "true" : undefined} onClick={handleClick}>
							<img src={`https://admin.ventadirekta.com${store_data.current_language.flag}`} alt="" width={30} height={30} />
						</Button>
						<Link to="/cart" style={{ color: colors.colorB }}>
							{/* <CssVarsProvider> */}
							<Badge badgeContent={cartLength} color="primary" variant="solid">
								<ShoppingCartOutlinedIcon />
							</Badge>
							{/* </CssVarsProvider> */}
						</Link>
					</div>
				</div>
				{!props.drawer ? (
					<>
						<div style={{ backgroundColor: theme.palette.background.default }}>
							<Spacer height={20} />
						</div>
						<Container style={{ backgroundColor: theme.palette.background.default }}>
							<div
								style={{
									display: "flex",
									justifyContent: "center",
									flexDirection: "row",
								}}>
								{collections_data.map((item, index) => (
									<div
										key={index}
										style={{
											display: "flex",
											justifyContent: "space-evenly",
										}}>
										<Link to={`/collection/${item.handle}`} style={{ textDecoration: "none", overflow: "hidden", textOverflow: "ellipsis", display: "-webkit-box", WebkitLineClamp: 1, WebkitBoxOrient: "vertical" }}>
											<div
												style={{
													fontFamily: "ClashDisplay-Regular",
													fontSize: 12,
													// paddingRight: 15,
													padding: "0 5px",
													color: theme.palette.text.primary,
												}}>
												{item.name}
											</div>
										</Link>
										<div
											style={{
												width: 1,
												height: 15,
												backgroundColor: colors.black,
											}}></div>
									</div>
								))}
							</div>
						</Container>

						<div style={{ backgroundColor: theme.palette.background.default }}>
							<Spacer height={15} />
						</div>
					</>
				) : (
					""
				)}
			</div>
			<Menu
				id="basic-menu"
				anchorEl={anchorEl}
				open={openMenu}
				onClose={handleClose}
				MenuListProps={{
					"aria-labelledby": "basic-button",
				}}>
				<MenuList>
					{JSON.parse(store_data.languages).map((item, index) => {
						return (
							<MenuItem
								key={index}
								onClick={(e) => {
									setLanguage(item.code);
								}}>
								<ListItemIcon>
									<img src={`https://admin.ventadirekta.com${item.flag}`} alt="" width={30} height={30} />
								</ListItemIcon>
								<ListItemText>{item.name}</ListItemText>
							</MenuItem>
						);
					})}
				</MenuList>
			</Menu>
		</>
	);
};
